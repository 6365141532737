@font-face {
  font-family: StabilGrotesk;
  src: url("StabilGrotesk-Light.43fc5a9c.otf");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: StabilGrotesk;
  src: url("StabilGrotesk-Medium.96d34900.otf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: KlarheitGrotesk;
  src: url("KlarheitGrotesk-Medium.a4eeae60.ttf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: KlarheitGrotesk;
  src: url("KlarheitGrotesk-Semibold.91e1f74a.ttf");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: KlarheitGrotesk;
  src: url("KlarheitGrotesk-Bold.9d32cbb4.ttf");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: KlarheitGrotesk;
  src: url("KlarheitGrotesk-Book.f27d752e.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.static {
  position: static;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.left-\[-12px\] {
  left: -12px;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

.z-\[60\] {
  z-index: 60;
}

.z-40 {
  z-index: 40;
}

.z-30 {
  z-index: 30;
}

.float-right {
  float: right;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-\[2px\] {
  margin-bottom: 2px;
}

.ml-auto {
  margin-left: auto;
}

.ml-2 {
  margin-left: .5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mb-32 {
  margin-bottom: 8rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-\[300px\] {
  height: 300px;
}

.h-5 {
  height: 1.25rem;
}

.h-4 {
  height: 1rem;
}

.h-full {
  height: 100%;
}

.h-\[360px\] {
  height: 360px;
}

.h-2 {
  height: .5rem;
}

.h-8 {
  height: 2rem;
}

.h-20 {
  height: 5rem;
}

.h-\[280px\] {
  height: 280px;
}

.h-14 {
  height: 3.5rem;
}

.h-6 {
  height: 1.5rem;
}

.h-\[57px\] {
  height: 57px;
}

.h-11 {
  height: 2.75rem;
}

.h-\[166px\] {
  height: 166px;
}

.h-10 {
  height: 2.5rem;
}

.h-\[302px\] {
  height: 302px;
}

.h-\[396px\] {
  height: 396px;
}

.h-12 {
  height: 3rem;
}

.h-24 {
  height: 6rem;
}

.max-h-\[220px\] {
  max-height: 220px;
}

.max-h-\[250px\] {
  max-height: 250px;
}

.min-h-screen {
  min-height: 100vh;
}

.min-h-\[356px\] {
  min-height: 356px;
}

.min-h-\[452px\] {
  min-height: 452px;
}

.min-h-\[260px\] {
  min-height: 260px;
}

.min-h-\[302px\] {
  min-height: 302px;
}

.w-full {
  width: 100%;
}

.w-5 {
  width: 1.25rem;
}

.w-4 {
  width: 1rem;
}

.w-16 {
  width: 4rem;
}

.w-8 {
  width: 2rem;
}

.w-20 {
  width: 5rem;
}

.w-14 {
  width: 3.5rem;
}

.w-6 {
  width: 1.5rem;
}

.w-\[24px\] {
  width: 24px;
}

.w-52 {
  width: 13rem;
}

.w-44 {
  width: 11rem;
}

.w-\[288px\] {
  width: 288px;
}

.w-36 {
  width: 9rem;
}

.w-24 {
  width: 6rem;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.min-w-\[24px\] {
  min-width: 24px;
}

.max-w-\[284px\] {
  max-width: 284px;
}

.max-w-\[220px\] {
  max-width: 220px;
}

.max-w-\[356px\] {
  max-width: 356px;
}

.max-w-\[380px\] {
  max-width: 380px;
}

.max-w-\[344px\] {
  max-width: 344px;
}

.max-w-\[320px\] {
  max-width: 320px;
}

.max-w-\[171px\] {
  max-width: 171px;
}

.max-w-\[294px\] {
  max-width: 294px;
}

.max-w-xs {
  max-width: 20rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@keyframes reverse-spin {
  from {
    transform: rotate(360deg);
  }
}

.animate-reverse-spin {
  animation: 1s linear infinite reverse-spin;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-x-2 {
  -moz-column-gap: .5rem;
  column-gap: .5rem;
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)) );
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(3rem * var(--tw-space-y-reverse));
}

.space-y-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-\[4px\] {
  border-radius: 4px;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-md {
  border-radius: .375rem;
}

.border {
  border-width: 1px;
}

.border-b-\[1\.5px\] {
  border-bottom-width: 1.5px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-none {
  border-style: none;
}

.border-black-10 {
  --tw-border-opacity: 1;
  border-color: rgb(209 209 209 / var(--tw-border-opacity));
}

.border-black-40 {
  --tw-border-opacity: 1;
  border-color: rgb(116 116 116 / var(--tw-border-opacity));
}

.border-\[\#E7E5E5\] {
  --tw-border-opacity: 1;
  border-color: rgb(231 229 229 / var(--tw-border-opacity));
}

.border-b-red {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(203 57 57 / var(--tw-border-opacity));
}

.border-b-black-20 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(162 162 162 / var(--tw-border-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-black-20 {
  --tw-bg-opacity: 1;
  background-color: rgb(162 162 162 / var(--tw-bg-opacity));
}

.bg-black-10 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 209 209 / var(--tw-bg-opacity));
}

.bg-\[\#ECECEC\] {
  --tw-bg-opacity: 1;
  background-color: rgb(236 236 236 / var(--tw-bg-opacity));
}

.bg-\[\#F2F2F2\] {
  --tw-bg-opacity: 1;
  background-color: rgb(242 242 242 / var(--tw-bg-opacity));
}

.bg-black-40 {
  --tw-bg-opacity: 1;
  background-color: rgb(116 116 116 / var(--tw-bg-opacity));
}

.bg-\[\#FFFFFF8F\] {
  background-color: #ffffff8f;
}

.bg-\[\#FFF7EB\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 247 235 / var(--tw-bg-opacity));
}

.bg-\[\#E7E7FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(231 231 255 / var(--tw-bg-opacity));
}

.bg-black\/80 {
  background-color: #171717cc;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-\[10px\] {
  padding: 10px;
}

.p-1 {
  padding: .25rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.pb-7 {
  padding-bottom: 1.75rem;
}

.pt-7 {
  padding-top: 1.75rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.uppercase {
  text-transform: uppercase;
}

.text-black-40 {
  --tw-text-opacity: 1;
  color: rgb(116 116 116 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(23 23 23 / var(--tw-text-opacity));
}

.text-black-80 {
  --tw-text-opacity: 1;
  color: rgb(69 69 69 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-red {
  --tw-text-opacity: 1;
  color: rgb(203 57 57 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-black-20 {
  --tw-text-opacity: 1;
  color: rgb(162 162 162 / var(--tw-text-opacity));
}

.text-red\/75 {
  color: #cb3939bf;
}

.text-\[\#875100\] {
  --tw-text-opacity: 1;
  color: rgb(135 81 0 / var(--tw-text-opacity));
}

.text-orange {
  --tw-text-opacity: 1;
  color: rgb(221 136 8 / var(--tw-text-opacity));
}

.text-purple-darker {
  --tw-text-opacity: 1;
  color: rgb(28 26 104 / var(--tw-text-opacity));
}

.text-\[\#29773E\] {
  --tw-text-opacity: 1;
  color: rgb(41 119 62 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-purple {
  --tw-text-opacity: 1;
  color: rgb(85 81 255 / var(--tw-text-opacity));
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-\[3000ms\] {
  transition-duration: 3s;
}

body {
  color: #454545;
  word-wrap: break-word;
  font-kerning: normal;
  font-family: StabilGrotesk, georgia, serif;
  font-weight: normal;
  position: relative;
}

.clickable-list-item:after {
  content: "";
  width: 100%;
  height: 1.5px;
  border: 1px solid #d1d1d1;
  display: block;
}

.clickable-list-item:last-child:after {
  border: none;
}

div.clickable-list-item:last-child {
  border-radius: 0 0 12px 12px;
}

.stepper-link:after {
  content: "";
  width: 24px;
  height: 1.2px;
  background-color: #d1d1d1;
  position: absolute;
  top: 16px;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.grotesk {
  font-family: KlarheitGrotesk, georgia, serif;
}

.lr-pagination {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.lr-pagination > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)) );
}

.lr-pagination {
  font-family: KlarheitGrotesk, georgia, serif;
  font-size: .75rem;
  font-weight: 600;
  line-height: 1rem;
}

.lr-pagination-item {
  height: 1.5rem;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(236 236 236 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(69 69 69 / var(--tw-text-opacity));
  border-radius: 4px;
  padding: .25rem .5rem;
  line-height: 1rem;
}

.lr-pagination-item_active {
  height: 1.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(69 69 69 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding: .25rem .5rem;
  line-height: 1rem;
}

.lr-pagination-previous, .lr-pagination-next {
  height: 1.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(209 209 209 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  padding-left: .25rem;
  padding-right: .25rem;
  display: flex;
}

.hover\:border:hover {
  border-width: 1px;
}

.hover\:border-black-10:hover {
  --tw-border-opacity: 1;
  border-color: rgb(209 209 209 / var(--tw-border-opacity));
}

@media (min-width: 640px) {
  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:block {
    display: block;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:rounded-2xl {
    border-radius: 1rem;
  }

  .sm\:border {
    border-width: 1px;
  }

  .sm\:border-\[\#E7E5E5\] {
    --tw-border-opacity: 1;
    border-color: rgb(231 229 229 / var(--tw-border-opacity));
  }

  .sm\:bg-\[\#FAFAFA\] {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity));
  }

  .sm\:pt-32 {
    padding-top: 8rem;
  }
}

@font-face {
  font-family: remixicon;
  src: url("remixicon.909e92ab.eot");
  src: url("remixicon.909e92ab.eot#iefix") format("embedded-opentype"), url("remixicon.aac295fa.woff2") format("woff2"), url("remixicon.db6033a1.woff") format("woff"), url("remixicon.b8e5d72d.ttf") format("truetype"), url("remixicon.7d2bcd3f.svg#remixicon") format("svg");
  font-display: swap;
}

[class^="ri-"], [class*=" ri-"] {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-family: remixicon !important;
}

.ri-lg {
  vertical-align: -.0667em;
  font-size: 1.3333em;
  line-height: .75em;
}

.ri-xl {
  vertical-align: -.075em;
  font-size: 1.5em;
  line-height: .6666em;
}

.ri-xxs {
  font-size: .5em;
}

.ri-xs {
  font-size: .75em;
}

.ri-sm {
  font-size: .875em;
}

.ri-1x {
  font-size: 1em;
}

.ri-2x {
  font-size: 2em;
}

.ri-3x {
  font-size: 3em;
}

.ri-4x {
  font-size: 4em;
}

.ri-5x {
  font-size: 5em;
}

.ri-6x {
  font-size: 6em;
}

.ri-7x {
  font-size: 7em;
}

.ri-8x {
  font-size: 8em;
}

.ri-9x {
  font-size: 9em;
}

.ri-10x {
  font-size: 10em;
}

.ri-fw {
  text-align: center;
  width: 1.25em;
}

.ri-24-hours-fill:before {
  content: "";
}

.ri-24-hours-line:before {
  content: "";
}

.ri-4k-fill:before {
  content: "";
}

.ri-4k-line:before {
  content: "";
}

.ri-a-b:before {
  content: "";
}

.ri-account-box-fill:before {
  content: "";
}

.ri-account-box-line:before {
  content: "";
}

.ri-account-circle-fill:before {
  content: "";
}

.ri-account-circle-line:before {
  content: "";
}

.ri-account-pin-box-fill:before {
  content: "";
}

.ri-account-pin-box-line:before {
  content: "";
}

.ri-account-pin-circle-fill:before {
  content: "";
}

.ri-account-pin-circle-line:before {
  content: "";
}

.ri-add-box-fill:before {
  content: "";
}

.ri-add-box-line:before {
  content: "";
}

.ri-add-circle-fill:before {
  content: "";
}

.ri-add-circle-line:before {
  content: "";
}

.ri-add-fill:before {
  content: "";
}

.ri-add-line:before {
  content: "";
}

.ri-admin-fill:before {
  content: "";
}

.ri-admin-line:before {
  content: "";
}

.ri-advertisement-fill:before {
  content: "";
}

.ri-advertisement-line:before {
  content: "";
}

.ri-airplay-fill:before {
  content: "";
}

.ri-airplay-line:before {
  content: "";
}

.ri-alarm-fill:before {
  content: "";
}

.ri-alarm-line:before {
  content: "";
}

.ri-alarm-warning-fill:before {
  content: "";
}

.ri-alarm-warning-line:before {
  content: "";
}

.ri-album-fill:before {
  content: "";
}

.ri-album-line:before {
  content: "";
}

.ri-alert-fill:before {
  content: "";
}

.ri-alert-line:before {
  content: "";
}

.ri-aliens-fill:before {
  content: "";
}

.ri-aliens-line:before {
  content: "";
}

.ri-align-bottom:before {
  content: "";
}

.ri-align-center:before {
  content: "";
}

.ri-align-justify:before {
  content: "";
}

.ri-align-left:before {
  content: "";
}

.ri-align-right:before {
  content: "";
}

.ri-align-top:before {
  content: "";
}

.ri-align-vertically:before {
  content: "";
}

.ri-alipay-fill:before {
  content: "";
}

.ri-alipay-line:before {
  content: "";
}

.ri-amazon-fill:before {
  content: "";
}

.ri-amazon-line:before {
  content: "";
}

.ri-anchor-fill:before {
  content: "";
}

.ri-anchor-line:before {
  content: "";
}

.ri-ancient-gate-fill:before {
  content: "";
}

.ri-ancient-gate-line:before {
  content: "";
}

.ri-ancient-pavilion-fill:before {
  content: "";
}

.ri-ancient-pavilion-line:before {
  content: "";
}

.ri-android-fill:before {
  content: "";
}

.ri-android-line:before {
  content: "";
}

.ri-angularjs-fill:before {
  content: "";
}

.ri-angularjs-line:before {
  content: "";
}

.ri-anticlockwise-2-fill:before {
  content: "";
}

.ri-anticlockwise-2-line:before {
  content: "";
}

.ri-anticlockwise-fill:before {
  content: "";
}

.ri-anticlockwise-line:before {
  content: "";
}

.ri-app-store-fill:before {
  content: "";
}

.ri-app-store-line:before {
  content: "";
}

.ri-apple-fill:before {
  content: "";
}

.ri-apple-line:before {
  content: "";
}

.ri-apps-2-fill:before {
  content: "";
}

.ri-apps-2-line:before {
  content: "";
}

.ri-apps-fill:before {
  content: "";
}

.ri-apps-line:before {
  content: "";
}

.ri-archive-drawer-fill:before {
  content: "";
}

.ri-archive-drawer-line:before {
  content: "";
}

.ri-archive-fill:before {
  content: "";
}

.ri-archive-line:before {
  content: "";
}

.ri-arrow-down-circle-fill:before {
  content: "";
}

.ri-arrow-down-circle-line:before {
  content: "";
}

.ri-arrow-down-fill:before {
  content: "";
}

.ri-arrow-down-line:before {
  content: "";
}

.ri-arrow-down-s-fill:before {
  content: "";
}

.ri-arrow-down-s-line:before {
  content: "";
}

.ri-arrow-drop-down-fill:before {
  content: "";
}

.ri-arrow-drop-down-line:before {
  content: "";
}

.ri-arrow-drop-left-fill:before {
  content: "";
}

.ri-arrow-drop-left-line:before {
  content: "";
}

.ri-arrow-drop-right-fill:before {
  content: "";
}

.ri-arrow-drop-right-line:before {
  content: "";
}

.ri-arrow-drop-up-fill:before {
  content: "";
}

.ri-arrow-drop-up-line:before {
  content: "";
}

.ri-arrow-go-back-fill:before {
  content: "";
}

.ri-arrow-go-back-line:before {
  content: "";
}

.ri-arrow-go-forward-fill:before {
  content: "";
}

.ri-arrow-go-forward-line:before {
  content: "";
}

.ri-arrow-left-circle-fill:before {
  content: "";
}

.ri-arrow-left-circle-line:before {
  content: "";
}

.ri-arrow-left-down-fill:before {
  content: "";
}

.ri-arrow-left-down-line:before {
  content: "";
}

.ri-arrow-left-fill:before {
  content: "";
}

.ri-arrow-left-line:before {
  content: "";
}

.ri-arrow-left-right-fill:before {
  content: "";
}

.ri-arrow-left-right-line:before {
  content: "";
}

.ri-arrow-left-s-fill:before {
  content: "";
}

.ri-arrow-left-s-line:before {
  content: "";
}

.ri-arrow-left-up-fill:before {
  content: "";
}

.ri-arrow-left-up-line:before {
  content: "";
}

.ri-arrow-right-circle-fill:before {
  content: "";
}

.ri-arrow-right-circle-line:before {
  content: "";
}

.ri-arrow-right-down-fill:before {
  content: "";
}

.ri-arrow-right-down-line:before {
  content: "";
}

.ri-arrow-right-fill:before {
  content: "";
}

.ri-arrow-right-line:before {
  content: "";
}

.ri-arrow-right-s-fill:before {
  content: "";
}

.ri-arrow-right-s-line:before {
  content: "";
}

.ri-arrow-right-up-fill:before {
  content: "";
}

.ri-arrow-right-up-line:before {
  content: "";
}

.ri-arrow-up-circle-fill:before {
  content: "";
}

.ri-arrow-up-circle-line:before {
  content: "";
}

.ri-arrow-up-down-fill:before {
  content: "";
}

.ri-arrow-up-down-line:before {
  content: "";
}

.ri-arrow-up-fill:before {
  content: "";
}

.ri-arrow-up-line:before {
  content: "";
}

.ri-arrow-up-s-fill:before {
  content: "";
}

.ri-arrow-up-s-line:before {
  content: "";
}

.ri-artboard-2-fill:before {
  content: "";
}

.ri-artboard-2-line:before {
  content: "";
}

.ri-artboard-fill:before {
  content: "";
}

.ri-artboard-line:before {
  content: "";
}

.ri-article-fill:before {
  content: "";
}

.ri-article-line:before {
  content: "";
}

.ri-aspect-ratio-fill:before {
  content: "";
}

.ri-aspect-ratio-line:before {
  content: "";
}

.ri-asterisk:before {
  content: "";
}

.ri-at-fill:before {
  content: "";
}

.ri-at-line:before {
  content: "";
}

.ri-attachment-2:before {
  content: "";
}

.ri-attachment-fill:before {
  content: "";
}

.ri-attachment-line:before {
  content: "";
}

.ri-auction-fill:before {
  content: "";
}

.ri-auction-line:before {
  content: "";
}

.ri-award-fill:before {
  content: "";
}

.ri-award-line:before {
  content: "";
}

.ri-baidu-fill:before {
  content: "";
}

.ri-baidu-line:before {
  content: "";
}

.ri-ball-pen-fill:before {
  content: "";
}

.ri-ball-pen-line:before {
  content: "";
}

.ri-bank-card-2-fill:before {
  content: "";
}

.ri-bank-card-2-line:before {
  content: "";
}

.ri-bank-card-fill:before {
  content: "";
}

.ri-bank-card-line:before {
  content: "";
}

.ri-bank-fill:before {
  content: "";
}

.ri-bank-line:before {
  content: "";
}

.ri-bar-chart-2-fill:before {
  content: "";
}

.ri-bar-chart-2-line:before {
  content: "";
}

.ri-bar-chart-box-fill:before {
  content: "";
}

.ri-bar-chart-box-line:before {
  content: "";
}

.ri-bar-chart-fill:before {
  content: "";
}

.ri-bar-chart-grouped-fill:before {
  content: "";
}

.ri-bar-chart-grouped-line:before {
  content: "";
}

.ri-bar-chart-horizontal-fill:before {
  content: "";
}

.ri-bar-chart-horizontal-line:before {
  content: "";
}

.ri-bar-chart-line:before {
  content: "";
}

.ri-barcode-box-fill:before {
  content: "";
}

.ri-barcode-box-line:before {
  content: "";
}

.ri-barcode-fill:before {
  content: "";
}

.ri-barcode-line:before {
  content: "";
}

.ri-barricade-fill:before {
  content: "";
}

.ri-barricade-line:before {
  content: "";
}

.ri-base-station-fill:before {
  content: "";
}

.ri-base-station-line:before {
  content: "";
}

.ri-basketball-fill:before {
  content: "";
}

.ri-basketball-line:before {
  content: "";
}

.ri-battery-2-charge-fill:before {
  content: "";
}

.ri-battery-2-charge-line:before {
  content: "";
}

.ri-battery-2-fill:before {
  content: "";
}

.ri-battery-2-line:before {
  content: "";
}

.ri-battery-charge-fill:before {
  content: "";
}

.ri-battery-charge-line:before {
  content: "";
}

.ri-battery-fill:before {
  content: "";
}

.ri-battery-line:before {
  content: "";
}

.ri-battery-low-fill:before {
  content: "";
}

.ri-battery-low-line:before {
  content: "";
}

.ri-battery-saver-fill:before {
  content: "";
}

.ri-battery-saver-line:before {
  content: "";
}

.ri-battery-share-fill:before {
  content: "";
}

.ri-battery-share-line:before {
  content: "";
}

.ri-bear-smile-fill:before {
  content: "";
}

.ri-bear-smile-line:before {
  content: "";
}

.ri-behance-fill:before {
  content: "";
}

.ri-behance-line:before {
  content: "";
}

.ri-bell-fill:before {
  content: "";
}

.ri-bell-line:before {
  content: "";
}

.ri-bike-fill:before {
  content: "";
}

.ri-bike-line:before {
  content: "";
}

.ri-bilibili-fill:before {
  content: "";
}

.ri-bilibili-line:before {
  content: "";
}

.ri-bill-fill:before {
  content: "";
}

.ri-bill-line:before {
  content: "";
}

.ri-billiards-fill:before {
  content: "";
}

.ri-billiards-line:before {
  content: "";
}

.ri-bit-coin-fill:before {
  content: "";
}

.ri-bit-coin-line:before {
  content: "";
}

.ri-blaze-fill:before {
  content: "";
}

.ri-blaze-line:before {
  content: "";
}

.ri-bluetooth-connect-fill:before {
  content: "";
}

.ri-bluetooth-connect-line:before {
  content: "";
}

.ri-bluetooth-fill:before {
  content: "";
}

.ri-bluetooth-line:before {
  content: "";
}

.ri-blur-off-fill:before {
  content: "";
}

.ri-blur-off-line:before {
  content: "";
}

.ri-body-scan-fill:before {
  content: "";
}

.ri-body-scan-line:before {
  content: "";
}

.ri-bold:before {
  content: "";
}

.ri-book-2-fill:before {
  content: "";
}

.ri-book-2-line:before {
  content: "";
}

.ri-book-3-fill:before {
  content: "";
}

.ri-book-3-line:before {
  content: "";
}

.ri-book-fill:before {
  content: "";
}

.ri-book-line:before {
  content: "";
}

.ri-book-mark-fill:before {
  content: "";
}

.ri-book-mark-line:before {
  content: "";
}

.ri-book-open-fill:before {
  content: "";
}

.ri-book-open-line:before {
  content: "";
}

.ri-book-read-fill:before {
  content: "";
}

.ri-book-read-line:before {
  content: "";
}

.ri-booklet-fill:before {
  content: "";
}

.ri-booklet-line:before {
  content: "";
}

.ri-bookmark-2-fill:before {
  content: "";
}

.ri-bookmark-2-line:before {
  content: "";
}

.ri-bookmark-3-fill:before {
  content: "";
}

.ri-bookmark-3-line:before {
  content: "";
}

.ri-bookmark-fill:before {
  content: "";
}

.ri-bookmark-line:before {
  content: "";
}

.ri-boxing-fill:before {
  content: "";
}

.ri-boxing-line:before {
  content: "";
}

.ri-braces-fill:before {
  content: "";
}

.ri-braces-line:before {
  content: "";
}

.ri-brackets-fill:before {
  content: "";
}

.ri-brackets-line:before {
  content: "";
}

.ri-briefcase-2-fill:before {
  content: "";
}

.ri-briefcase-2-line:before {
  content: "";
}

.ri-briefcase-3-fill:before {
  content: "";
}

.ri-briefcase-3-line:before {
  content: "";
}

.ri-briefcase-4-fill:before {
  content: "";
}

.ri-briefcase-4-line:before {
  content: "";
}

.ri-briefcase-5-fill:before {
  content: "";
}

.ri-briefcase-5-line:before {
  content: "";
}

.ri-briefcase-fill:before {
  content: "";
}

.ri-briefcase-line:before {
  content: "";
}

.ri-bring-forward:before {
  content: "";
}

.ri-bring-to-front:before {
  content: "";
}

.ri-broadcast-fill:before {
  content: "";
}

.ri-broadcast-line:before {
  content: "";
}

.ri-brush-2-fill:before {
  content: "";
}

.ri-brush-2-line:before {
  content: "";
}

.ri-brush-3-fill:before {
  content: "";
}

.ri-brush-3-line:before {
  content: "";
}

.ri-brush-4-fill:before {
  content: "";
}

.ri-brush-4-line:before {
  content: "";
}

.ri-brush-fill:before {
  content: "";
}

.ri-brush-line:before {
  content: "";
}

.ri-bubble-chart-fill:before {
  content: "";
}

.ri-bubble-chart-line:before {
  content: "";
}

.ri-bug-2-fill:before {
  content: "";
}

.ri-bug-2-line:before {
  content: "";
}

.ri-bug-fill:before {
  content: "";
}

.ri-bug-line:before {
  content: "";
}

.ri-building-2-fill:before {
  content: "";
}

.ri-building-2-line:before {
  content: "";
}

.ri-building-3-fill:before {
  content: "";
}

.ri-building-3-line:before {
  content: "";
}

.ri-building-4-fill:before {
  content: "";
}

.ri-building-4-line:before {
  content: "";
}

.ri-building-fill:before {
  content: "";
}

.ri-building-line:before {
  content: "";
}

.ri-bus-2-fill:before {
  content: "";
}

.ri-bus-2-line:before {
  content: "";
}

.ri-bus-fill:before {
  content: "";
}

.ri-bus-line:before {
  content: "";
}

.ri-bus-wifi-fill:before {
  content: "";
}

.ri-bus-wifi-line:before {
  content: "";
}

.ri-cactus-fill:before {
  content: "";
}

.ri-cactus-line:before {
  content: "";
}

.ri-cake-2-fill:before {
  content: "";
}

.ri-cake-2-line:before {
  content: "";
}

.ri-cake-3-fill:before {
  content: "";
}

.ri-cake-3-line:before {
  content: "";
}

.ri-cake-fill:before {
  content: "";
}

.ri-cake-line:before {
  content: "";
}

.ri-calculator-fill:before {
  content: "";
}

.ri-calculator-line:before {
  content: "";
}

.ri-calendar-2-fill:before {
  content: "";
}

.ri-calendar-2-line:before {
  content: "";
}

.ri-calendar-check-fill:before {
  content: "";
}

.ri-calendar-check-line:before {
  content: "";
}

.ri-calendar-event-fill:before {
  content: "";
}

.ri-calendar-event-line:before {
  content: "";
}

.ri-calendar-fill:before {
  content: "";
}

.ri-calendar-line:before {
  content: "";
}

.ri-calendar-todo-fill:before {
  content: "";
}

.ri-calendar-todo-line:before {
  content: "";
}

.ri-camera-2-fill:before {
  content: "";
}

.ri-camera-2-line:before {
  content: "";
}

.ri-camera-3-fill:before {
  content: "";
}

.ri-camera-3-line:before {
  content: "";
}

.ri-camera-fill:before {
  content: "";
}

.ri-camera-lens-fill:before {
  content: "";
}

.ri-camera-lens-line:before {
  content: "";
}

.ri-camera-line:before {
  content: "";
}

.ri-camera-off-fill:before {
  content: "";
}

.ri-camera-off-line:before {
  content: "";
}

.ri-camera-switch-fill:before {
  content: "";
}

.ri-camera-switch-line:before {
  content: "";
}

.ri-capsule-fill:before {
  content: "";
}

.ri-capsule-line:before {
  content: "";
}

.ri-car-fill:before {
  content: "";
}

.ri-car-line:before {
  content: "";
}

.ri-car-washing-fill:before {
  content: "";
}

.ri-car-washing-line:before {
  content: "";
}

.ri-caravan-fill:before {
  content: "";
}

.ri-caravan-line:before {
  content: "";
}

.ri-cast-fill:before {
  content: "";
}

.ri-cast-line:before {
  content: "";
}

.ri-cellphone-fill:before {
  content: "";
}

.ri-cellphone-line:before {
  content: "";
}

.ri-celsius-fill:before {
  content: "";
}

.ri-celsius-line:before {
  content: "";
}

.ri-centos-fill:before {
  content: "";
}

.ri-centos-line:before {
  content: "";
}

.ri-character-recognition-fill:before {
  content: "";
}

.ri-character-recognition-line:before {
  content: "";
}

.ri-charging-pile-2-fill:before {
  content: "";
}

.ri-charging-pile-2-line:before {
  content: "";
}

.ri-charging-pile-fill:before {
  content: "";
}

.ri-charging-pile-line:before {
  content: "";
}

.ri-chat-1-fill:before {
  content: "";
}

.ri-chat-1-line:before {
  content: "";
}

.ri-chat-2-fill:before {
  content: "";
}

.ri-chat-2-line:before {
  content: "";
}

.ri-chat-3-fill:before {
  content: "";
}

.ri-chat-3-line:before {
  content: "";
}

.ri-chat-4-fill:before {
  content: "";
}

.ri-chat-4-line:before {
  content: "";
}

.ri-chat-check-fill:before {
  content: "";
}

.ri-chat-check-line:before {
  content: "";
}

.ri-chat-delete-fill:before {
  content: "";
}

.ri-chat-delete-line:before {
  content: "";
}

.ri-chat-download-fill:before {
  content: "";
}

.ri-chat-download-line:before {
  content: "";
}

.ri-chat-follow-up-fill:before {
  content: "";
}

.ri-chat-follow-up-line:before {
  content: "";
}

.ri-chat-forward-fill:before {
  content: "";
}

.ri-chat-forward-line:before {
  content: "";
}

.ri-chat-heart-fill:before {
  content: "";
}

.ri-chat-heart-line:before {
  content: "";
}

.ri-chat-history-fill:before {
  content: "";
}

.ri-chat-history-line:before {
  content: "";
}

.ri-chat-new-fill:before {
  content: "";
}

.ri-chat-new-line:before {
  content: "";
}

.ri-chat-off-fill:before {
  content: "";
}

.ri-chat-off-line:before {
  content: "";
}

.ri-chat-poll-fill:before {
  content: "";
}

.ri-chat-poll-line:before {
  content: "";
}

.ri-chat-private-fill:before {
  content: "";
}

.ri-chat-private-line:before {
  content: "";
}

.ri-chat-quote-fill:before {
  content: "";
}

.ri-chat-quote-line:before {
  content: "";
}

.ri-chat-settings-fill:before {
  content: "";
}

.ri-chat-settings-line:before {
  content: "";
}

.ri-chat-smile-2-fill:before {
  content: "";
}

.ri-chat-smile-2-line:before {
  content: "";
}

.ri-chat-smile-3-fill:before {
  content: "";
}

.ri-chat-smile-3-line:before {
  content: "";
}

.ri-chat-smile-fill:before {
  content: "";
}

.ri-chat-smile-line:before {
  content: "";
}

.ri-chat-upload-fill:before {
  content: "";
}

.ri-chat-upload-line:before {
  content: "";
}

.ri-chat-voice-fill:before {
  content: "";
}

.ri-chat-voice-line:before {
  content: "";
}

.ri-check-double-fill:before {
  content: "";
}

.ri-check-double-line:before {
  content: "";
}

.ri-check-fill:before {
  content: "";
}

.ri-check-line:before {
  content: "";
}

.ri-checkbox-blank-circle-fill:before {
  content: "";
}

.ri-checkbox-blank-circle-line:before {
  content: "";
}

.ri-checkbox-blank-fill:before {
  content: "";
}

.ri-checkbox-blank-line:before {
  content: "";
}

.ri-checkbox-circle-fill:before {
  content: "";
}

.ri-checkbox-circle-line:before {
  content: "";
}

.ri-checkbox-fill:before {
  content: "";
}

.ri-checkbox-indeterminate-fill:before {
  content: "";
}

.ri-checkbox-indeterminate-line:before {
  content: "";
}

.ri-checkbox-line:before {
  content: "";
}

.ri-checkbox-multiple-blank-fill:before {
  content: "";
}

.ri-checkbox-multiple-blank-line:before {
  content: "";
}

.ri-checkbox-multiple-fill:before {
  content: "";
}

.ri-checkbox-multiple-line:before {
  content: "";
}

.ri-china-railway-fill:before {
  content: "";
}

.ri-china-railway-line:before {
  content: "";
}

.ri-chrome-fill:before {
  content: "";
}

.ri-chrome-line:before {
  content: "";
}

.ri-clapperboard-fill:before {
  content: "";
}

.ri-clapperboard-line:before {
  content: "";
}

.ri-clipboard-fill:before {
  content: "";
}

.ri-clipboard-line:before {
  content: "";
}

.ri-clockwise-2-fill:before {
  content: "";
}

.ri-clockwise-2-line:before {
  content: "";
}

.ri-clockwise-fill:before {
  content: "";
}

.ri-clockwise-line:before {
  content: "";
}

.ri-close-circle-fill:before {
  content: "";
}

.ri-close-circle-line:before {
  content: "";
}

.ri-close-fill:before {
  content: "";
}

.ri-close-line:before {
  content: "";
}

.ri-closed-captioning-fill:before {
  content: "";
}

.ri-closed-captioning-line:before {
  content: "";
}

.ri-cloud-fill:before {
  content: "";
}

.ri-cloud-line:before {
  content: "";
}

.ri-cloud-off-fill:before {
  content: "";
}

.ri-cloud-off-line:before {
  content: "";
}

.ri-cloud-windy-fill:before {
  content: "";
}

.ri-cloud-windy-line:before {
  content: "";
}

.ri-cloudy-2-fill:before {
  content: "";
}

.ri-cloudy-2-line:before {
  content: "";
}

.ri-cloudy-fill:before {
  content: "";
}

.ri-cloudy-line:before {
  content: "";
}

.ri-code-box-fill:before {
  content: "";
}

.ri-code-box-line:before {
  content: "";
}

.ri-code-fill:before {
  content: "";
}

.ri-code-line:before {
  content: "";
}

.ri-code-s-fill:before {
  content: "";
}

.ri-code-s-line:before {
  content: "";
}

.ri-code-s-slash-fill:before {
  content: "";
}

.ri-code-s-slash-line:before {
  content: "";
}

.ri-code-view:before {
  content: "";
}

.ri-codepen-fill:before {
  content: "";
}

.ri-codepen-line:before {
  content: "";
}

.ri-coin-fill:before {
  content: "";
}

.ri-coin-line:before {
  content: "";
}

.ri-coins-fill:before {
  content: "";
}

.ri-coins-line:before {
  content: "";
}

.ri-collage-fill:before {
  content: "";
}

.ri-collage-line:before {
  content: "";
}

.ri-command-fill:before {
  content: "";
}

.ri-command-line:before {
  content: "";
}

.ri-community-fill:before {
  content: "";
}

.ri-community-line:before {
  content: "";
}

.ri-compass-2-fill:before {
  content: "";
}

.ri-compass-2-line:before {
  content: "";
}

.ri-compass-3-fill:before {
  content: "";
}

.ri-compass-3-line:before {
  content: "";
}

.ri-compass-4-fill:before {
  content: "";
}

.ri-compass-4-line:before {
  content: "";
}

.ri-compass-discover-fill:before {
  content: "";
}

.ri-compass-discover-line:before {
  content: "";
}

.ri-compass-fill:before {
  content: "";
}

.ri-compass-line:before {
  content: "";
}

.ri-compasses-2-fill:before {
  content: "";
}

.ri-compasses-2-line:before {
  content: "";
}

.ri-compasses-fill:before {
  content: "";
}

.ri-compasses-line:before {
  content: "";
}

.ri-computer-fill:before {
  content: "";
}

.ri-computer-line:before {
  content: "";
}

.ri-contacts-book-2-fill:before {
  content: "";
}

.ri-contacts-book-2-line:before {
  content: "";
}

.ri-contacts-book-fill:before {
  content: "";
}

.ri-contacts-book-line:before {
  content: "";
}

.ri-contacts-book-upload-fill:before {
  content: "";
}

.ri-contacts-book-upload-line:before {
  content: "";
}

.ri-contacts-fill:before {
  content: "";
}

.ri-contacts-line:before {
  content: "";
}

.ri-contrast-2-fill:before {
  content: "";
}

.ri-contrast-2-line:before {
  content: "";
}

.ri-contrast-drop-2-fill:before {
  content: "";
}

.ri-contrast-drop-2-line:before {
  content: "";
}

.ri-contrast-drop-fill:before {
  content: "";
}

.ri-contrast-drop-line:before {
  content: "";
}

.ri-contrast-fill:before {
  content: "";
}

.ri-contrast-line:before {
  content: "";
}

.ri-copper-coin-fill:before {
  content: "";
}

.ri-copper-coin-line:before {
  content: "";
}

.ri-copper-diamond-fill:before {
  content: "";
}

.ri-copper-diamond-line:before {
  content: "";
}

.ri-copyleft-fill:before {
  content: "";
}

.ri-copyleft-line:before {
  content: "";
}

.ri-copyright-fill:before {
  content: "";
}

.ri-copyright-line:before {
  content: "";
}

.ri-coreos-fill:before {
  content: "";
}

.ri-coreos-line:before {
  content: "";
}

.ri-coupon-2-fill:before {
  content: "";
}

.ri-coupon-2-line:before {
  content: "";
}

.ri-coupon-3-fill:before {
  content: "";
}

.ri-coupon-3-line:before {
  content: "";
}

.ri-coupon-4-fill:before {
  content: "";
}

.ri-coupon-4-line:before {
  content: "";
}

.ri-coupon-5-fill:before {
  content: "";
}

.ri-coupon-5-line:before {
  content: "";
}

.ri-coupon-fill:before {
  content: "";
}

.ri-coupon-line:before {
  content: "";
}

.ri-cpu-fill:before {
  content: "";
}

.ri-cpu-line:before {
  content: "";
}

.ri-creative-commons-by-fill:before {
  content: "";
}

.ri-creative-commons-by-line:before {
  content: "";
}

.ri-creative-commons-fill:before {
  content: "";
}

.ri-creative-commons-line:before {
  content: "";
}

.ri-creative-commons-nc-fill:before {
  content: "";
}

.ri-creative-commons-nc-line:before {
  content: "";
}

.ri-creative-commons-nd-fill:before {
  content: "";
}

.ri-creative-commons-nd-line:before {
  content: "";
}

.ri-creative-commons-sa-fill:before {
  content: "";
}

.ri-creative-commons-sa-line:before {
  content: "";
}

.ri-creative-commons-zero-fill:before {
  content: "";
}

.ri-creative-commons-zero-line:before {
  content: "";
}

.ri-criminal-fill:before {
  content: "";
}

.ri-criminal-line:before {
  content: "";
}

.ri-crop-2-fill:before {
  content: "";
}

.ri-crop-2-line:before {
  content: "";
}

.ri-crop-fill:before {
  content: "";
}

.ri-crop-line:before {
  content: "";
}

.ri-css3-fill:before {
  content: "";
}

.ri-css3-line:before {
  content: "";
}

.ri-cup-fill:before {
  content: "";
}

.ri-cup-line:before {
  content: "";
}

.ri-currency-fill:before {
  content: "";
}

.ri-currency-line:before {
  content: "";
}

.ri-cursor-fill:before {
  content: "";
}

.ri-cursor-line:before {
  content: "";
}

.ri-customer-service-2-fill:before {
  content: "";
}

.ri-customer-service-2-line:before {
  content: "";
}

.ri-customer-service-fill:before {
  content: "";
}

.ri-customer-service-line:before {
  content: "";
}

.ri-dashboard-2-fill:before {
  content: "";
}

.ri-dashboard-2-line:before {
  content: "";
}

.ri-dashboard-3-fill:before {
  content: "";
}

.ri-dashboard-3-line:before {
  content: "";
}

.ri-dashboard-fill:before {
  content: "";
}

.ri-dashboard-line:before {
  content: "";
}

.ri-database-2-fill:before {
  content: "";
}

.ri-database-2-line:before {
  content: "";
}

.ri-database-fill:before {
  content: "";
}

.ri-database-line:before {
  content: "";
}

.ri-delete-back-2-fill:before {
  content: "";
}

.ri-delete-back-2-line:before {
  content: "";
}

.ri-delete-back-fill:before {
  content: "";
}

.ri-delete-back-line:before {
  content: "";
}

.ri-delete-bin-2-fill:before {
  content: "";
}

.ri-delete-bin-2-line:before {
  content: "";
}

.ri-delete-bin-3-fill:before {
  content: "";
}

.ri-delete-bin-3-line:before {
  content: "";
}

.ri-delete-bin-4-fill:before {
  content: "";
}

.ri-delete-bin-4-line:before {
  content: "";
}

.ri-delete-bin-5-fill:before {
  content: "";
}

.ri-delete-bin-5-line:before {
  content: "";
}

.ri-delete-bin-6-fill:before {
  content: "";
}

.ri-delete-bin-6-line:before {
  content: "";
}

.ri-delete-bin-7-fill:before {
  content: "";
}

.ri-delete-bin-7-line:before {
  content: "";
}

.ri-delete-bin-fill:before {
  content: "";
}

.ri-delete-bin-line:before {
  content: "";
}

.ri-delete-column:before {
  content: "";
}

.ri-delete-row:before {
  content: "";
}

.ri-device-fill:before {
  content: "";
}

.ri-device-line:before {
  content: "";
}

.ri-device-recover-fill:before {
  content: "";
}

.ri-device-recover-line:before {
  content: "";
}

.ri-dingding-fill:before {
  content: "";
}

.ri-dingding-line:before {
  content: "";
}

.ri-direction-fill:before {
  content: "";
}

.ri-direction-line:before {
  content: "";
}

.ri-disc-fill:before {
  content: "";
}

.ri-disc-line:before {
  content: "";
}

.ri-discord-fill:before {
  content: "";
}

.ri-discord-line:before {
  content: "";
}

.ri-discuss-fill:before {
  content: "";
}

.ri-discuss-line:before {
  content: "";
}

.ri-dislike-fill:before {
  content: "";
}

.ri-dislike-line:before {
  content: "";
}

.ri-disqus-fill:before {
  content: "";
}

.ri-disqus-line:before {
  content: "";
}

.ri-divide-fill:before {
  content: "";
}

.ri-divide-line:before {
  content: "";
}

.ri-donut-chart-fill:before {
  content: "";
}

.ri-donut-chart-line:before {
  content: "";
}

.ri-door-closed-fill:before {
  content: "";
}

.ri-door-closed-line:before {
  content: "";
}

.ri-door-fill:before {
  content: "";
}

.ri-door-line:before {
  content: "";
}

.ri-door-lock-box-fill:before {
  content: "";
}

.ri-door-lock-box-line:before {
  content: "";
}

.ri-door-lock-fill:before {
  content: "";
}

.ri-door-lock-line:before {
  content: "";
}

.ri-door-open-fill:before {
  content: "";
}

.ri-door-open-line:before {
  content: "";
}

.ri-dossier-fill:before {
  content: "";
}

.ri-dossier-line:before {
  content: "";
}

.ri-douban-fill:before {
  content: "";
}

.ri-douban-line:before {
  content: "";
}

.ri-double-quotes-l:before {
  content: "";
}

.ri-double-quotes-r:before {
  content: "";
}

.ri-download-2-fill:before {
  content: "";
}

.ri-download-2-line:before {
  content: "";
}

.ri-download-cloud-2-fill:before {
  content: "";
}

.ri-download-cloud-2-line:before {
  content: "";
}

.ri-download-cloud-fill:before {
  content: "";
}

.ri-download-cloud-line:before {
  content: "";
}

.ri-download-fill:before {
  content: "";
}

.ri-download-line:before {
  content: "";
}

.ri-draft-fill:before {
  content: "";
}

.ri-draft-line:before {
  content: "";
}

.ri-drag-drop-fill:before {
  content: "";
}

.ri-drag-drop-line:before {
  content: "";
}

.ri-drag-move-2-fill:before {
  content: "";
}

.ri-drag-move-2-line:before {
  content: "";
}

.ri-drag-move-fill:before {
  content: "";
}

.ri-drag-move-line:before {
  content: "";
}

.ri-dribbble-fill:before {
  content: "";
}

.ri-dribbble-line:before {
  content: "";
}

.ri-drive-fill:before {
  content: "";
}

.ri-drive-line:before {
  content: "";
}

.ri-drizzle-fill:before {
  content: "";
}

.ri-drizzle-line:before {
  content: "";
}

.ri-drop-fill:before {
  content: "";
}

.ri-drop-line:before {
  content: "";
}

.ri-dropbox-fill:before {
  content: "";
}

.ri-dropbox-line:before {
  content: "";
}

.ri-dual-sim-1-fill:before {
  content: "";
}

.ri-dual-sim-1-line:before {
  content: "";
}

.ri-dual-sim-2-fill:before {
  content: "";
}

.ri-dual-sim-2-line:before {
  content: "";
}

.ri-dv-fill:before {
  content: "";
}

.ri-dv-line:before {
  content: "";
}

.ri-dvd-fill:before {
  content: "";
}

.ri-dvd-line:before {
  content: "";
}

.ri-e-bike-2-fill:before {
  content: "";
}

.ri-e-bike-2-line:before {
  content: "";
}

.ri-e-bike-fill:before {
  content: "";
}

.ri-e-bike-line:before {
  content: "";
}

.ri-earth-fill:before {
  content: "";
}

.ri-earth-line:before {
  content: "";
}

.ri-earthquake-fill:before {
  content: "";
}

.ri-earthquake-line:before {
  content: "";
}

.ri-edge-fill:before {
  content: "";
}

.ri-edge-line:before {
  content: "";
}

.ri-edit-2-fill:before {
  content: "";
}

.ri-edit-2-line:before {
  content: "";
}

.ri-edit-box-fill:before {
  content: "";
}

.ri-edit-box-line:before {
  content: "";
}

.ri-edit-circle-fill:before {
  content: "";
}

.ri-edit-circle-line:before {
  content: "";
}

.ri-edit-fill:before {
  content: "";
}

.ri-edit-line:before {
  content: "";
}

.ri-eject-fill:before {
  content: "";
}

.ri-eject-line:before {
  content: "";
}

.ri-emotion-2-fill:before {
  content: "";
}

.ri-emotion-2-line:before {
  content: "";
}

.ri-emotion-fill:before {
  content: "";
}

.ri-emotion-happy-fill:before {
  content: "";
}

.ri-emotion-happy-line:before {
  content: "";
}

.ri-emotion-laugh-fill:before {
  content: "";
}

.ri-emotion-laugh-line:before {
  content: "";
}

.ri-emotion-line:before {
  content: "";
}

.ri-emotion-normal-fill:before {
  content: "";
}

.ri-emotion-normal-line:before {
  content: "";
}

.ri-emotion-sad-fill:before {
  content: "";
}

.ri-emotion-sad-line:before {
  content: "";
}

.ri-emotion-unhappy-fill:before {
  content: "";
}

.ri-emotion-unhappy-line:before {
  content: "";
}

.ri-empathize-fill:before {
  content: "";
}

.ri-empathize-line:before {
  content: "";
}

.ri-emphasis-cn:before {
  content: "";
}

.ri-emphasis:before {
  content: "";
}

.ri-english-input:before {
  content: "";
}

.ri-equalizer-fill:before {
  content: "";
}

.ri-equalizer-line:before {
  content: "";
}

.ri-eraser-fill:before {
  content: "";
}

.ri-eraser-line:before {
  content: "";
}

.ri-error-warning-fill:before {
  content: "";
}

.ri-error-warning-line:before {
  content: "";
}

.ri-evernote-fill:before {
  content: "";
}

.ri-evernote-line:before {
  content: "";
}

.ri-exchange-box-fill:before {
  content: "";
}

.ri-exchange-box-line:before {
  content: "";
}

.ri-exchange-cny-fill:before {
  content: "";
}

.ri-exchange-cny-line:before {
  content: "";
}

.ri-exchange-dollar-fill:before {
  content: "";
}

.ri-exchange-dollar-line:before {
  content: "";
}

.ri-exchange-fill:before {
  content: "";
}

.ri-exchange-funds-fill:before {
  content: "";
}

.ri-exchange-funds-line:before {
  content: "";
}

.ri-exchange-line:before {
  content: "";
}

.ri-external-link-fill:before {
  content: "";
}

.ri-external-link-line:before {
  content: "";
}

.ri-eye-2-fill:before {
  content: "";
}

.ri-eye-2-line:before {
  content: "";
}

.ri-eye-close-fill:before {
  content: "";
}

.ri-eye-close-line:before {
  content: "";
}

.ri-eye-fill:before {
  content: "";
}

.ri-eye-line:before {
  content: "";
}

.ri-eye-off-fill:before {
  content: "";
}

.ri-eye-off-line:before {
  content: "";
}

.ri-facebook-box-fill:before {
  content: "";
}

.ri-facebook-box-line:before {
  content: "";
}

.ri-facebook-circle-fill:before {
  content: "";
}

.ri-facebook-circle-line:before {
  content: "";
}

.ri-facebook-fill:before {
  content: "";
}

.ri-facebook-line:before {
  content: "";
}

.ri-fahrenheit-fill:before {
  content: "";
}

.ri-fahrenheit-line:before {
  content: "";
}

.ri-feedback-fill:before {
  content: "";
}

.ri-feedback-line:before {
  content: "";
}

.ri-file-2-fill:before {
  content: "";
}

.ri-file-2-line:before {
  content: "";
}

.ri-file-3-fill:before {
  content: "";
}

.ri-file-3-line:before {
  content: "";
}

.ri-file-4-fill:before {
  content: "";
}

.ri-file-4-line:before {
  content: "";
}

.ri-file-add-fill:before {
  content: "";
}

.ri-file-add-line:before {
  content: "";
}

.ri-file-chart-2-fill:before {
  content: "";
}

.ri-file-chart-2-line:before {
  content: "";
}

.ri-file-chart-fill:before {
  content: "";
}

.ri-file-chart-line:before {
  content: "";
}

.ri-file-cloud-fill:before {
  content: "";
}

.ri-file-cloud-line:before {
  content: "";
}

.ri-file-code-fill:before {
  content: "";
}

.ri-file-code-line:before {
  content: "";
}

.ri-file-copy-2-fill:before {
  content: "";
}

.ri-file-copy-2-line:before {
  content: "";
}

.ri-file-copy-fill:before {
  content: "";
}

.ri-file-copy-line:before {
  content: "";
}

.ri-file-damage-fill:before {
  content: "";
}

.ri-file-damage-line:before {
  content: "";
}

.ri-file-download-fill:before {
  content: "";
}

.ri-file-download-line:before {
  content: "";
}

.ri-file-edit-fill:before {
  content: "";
}

.ri-file-edit-line:before {
  content: "";
}

.ri-file-excel-2-fill:before {
  content: "";
}

.ri-file-excel-2-line:before {
  content: "";
}

.ri-file-excel-fill:before {
  content: "";
}

.ri-file-excel-line:before {
  content: "";
}

.ri-file-fill:before {
  content: "";
}

.ri-file-forbid-fill:before {
  content: "";
}

.ri-file-forbid-line:before {
  content: "";
}

.ri-file-gif-fill:before {
  content: "";
}

.ri-file-gif-line:before {
  content: "";
}

.ri-file-history-fill:before {
  content: "";
}

.ri-file-history-line:before {
  content: "";
}

.ri-file-hwp-fill:before {
  content: "";
}

.ri-file-hwp-line:before {
  content: "";
}

.ri-file-info-fill:before {
  content: "";
}

.ri-file-info-line:before {
  content: "";
}

.ri-file-line:before {
  content: "";
}

.ri-file-list-2-fill:before {
  content: "";
}

.ri-file-list-2-line:before {
  content: "";
}

.ri-file-list-3-fill:before {
  content: "";
}

.ri-file-list-3-line:before {
  content: "";
}

.ri-file-list-fill:before {
  content: "";
}

.ri-file-list-line:before {
  content: "";
}

.ri-file-lock-fill:before {
  content: "";
}

.ri-file-lock-line:before {
  content: "";
}

.ri-file-mark-fill:before {
  content: "";
}

.ri-file-mark-line:before {
  content: "";
}

.ri-file-music-fill:before {
  content: "";
}

.ri-file-music-line:before {
  content: "";
}

.ri-file-paper-2-fill:before {
  content: "";
}

.ri-file-paper-2-line:before {
  content: "";
}

.ri-file-paper-fill:before {
  content: "";
}

.ri-file-paper-line:before {
  content: "";
}

.ri-file-pdf-fill:before {
  content: "";
}

.ri-file-pdf-line:before {
  content: "";
}

.ri-file-ppt-2-fill:before {
  content: "";
}

.ri-file-ppt-2-line:before {
  content: "";
}

.ri-file-ppt-fill:before {
  content: "";
}

.ri-file-ppt-line:before {
  content: "";
}

.ri-file-reduce-fill:before {
  content: "";
}

.ri-file-reduce-line:before {
  content: "";
}

.ri-file-search-fill:before {
  content: "";
}

.ri-file-search-line:before {
  content: "";
}

.ri-file-settings-fill:before {
  content: "";
}

.ri-file-settings-line:before {
  content: "";
}

.ri-file-shield-2-fill:before {
  content: "";
}

.ri-file-shield-2-line:before {
  content: "";
}

.ri-file-shield-fill:before {
  content: "";
}

.ri-file-shield-line:before {
  content: "";
}

.ri-file-shred-fill:before {
  content: "";
}

.ri-file-shred-line:before {
  content: "";
}

.ri-file-text-fill:before {
  content: "";
}

.ri-file-text-line:before {
  content: "";
}

.ri-file-transfer-fill:before {
  content: "";
}

.ri-file-transfer-line:before {
  content: "";
}

.ri-file-unknow-fill:before {
  content: "";
}

.ri-file-unknow-line:before {
  content: "";
}

.ri-file-upload-fill:before {
  content: "";
}

.ri-file-upload-line:before {
  content: "";
}

.ri-file-user-fill:before {
  content: "";
}

.ri-file-user-line:before {
  content: "";
}

.ri-file-warning-fill:before {
  content: "";
}

.ri-file-warning-line:before {
  content: "";
}

.ri-file-word-2-fill:before {
  content: "";
}

.ri-file-word-2-line:before {
  content: "";
}

.ri-file-word-fill:before {
  content: "";
}

.ri-file-word-line:before {
  content: "";
}

.ri-file-zip-fill:before {
  content: "";
}

.ri-file-zip-line:before {
  content: "";
}

.ri-film-fill:before {
  content: "";
}

.ri-film-line:before {
  content: "";
}

.ri-filter-2-fill:before {
  content: "";
}

.ri-filter-2-line:before {
  content: "";
}

.ri-filter-3-fill:before {
  content: "";
}

.ri-filter-3-line:before {
  content: "";
}

.ri-filter-fill:before {
  content: "";
}

.ri-filter-line:before {
  content: "";
}

.ri-filter-off-fill:before {
  content: "";
}

.ri-filter-off-line:before {
  content: "";
}

.ri-find-replace-fill:before {
  content: "";
}

.ri-find-replace-line:before {
  content: "";
}

.ri-finder-fill:before {
  content: "";
}

.ri-finder-line:before {
  content: "";
}

.ri-fingerprint-2-fill:before {
  content: "";
}

.ri-fingerprint-2-line:before {
  content: "";
}

.ri-fingerprint-fill:before {
  content: "";
}

.ri-fingerprint-line:before {
  content: "";
}

.ri-fire-fill:before {
  content: "";
}

.ri-fire-line:before {
  content: "";
}

.ri-firefox-fill:before {
  content: "";
}

.ri-firefox-line:before {
  content: "";
}

.ri-first-aid-kit-fill:before {
  content: "";
}

.ri-first-aid-kit-line:before {
  content: "";
}

.ri-flag-2-fill:before {
  content: "";
}

.ri-flag-2-line:before {
  content: "";
}

.ri-flag-fill:before {
  content: "";
}

.ri-flag-line:before {
  content: "";
}

.ri-flashlight-fill:before {
  content: "";
}

.ri-flashlight-line:before {
  content: "";
}

.ri-flask-fill:before {
  content: "";
}

.ri-flask-line:before {
  content: "";
}

.ri-flight-land-fill:before {
  content: "";
}

.ri-flight-land-line:before {
  content: "";
}

.ri-flight-takeoff-fill:before {
  content: "";
}

.ri-flight-takeoff-line:before {
  content: "";
}

.ri-flood-fill:before {
  content: "";
}

.ri-flood-line:before {
  content: "";
}

.ri-flow-chart:before {
  content: "";
}

.ri-flutter-fill:before {
  content: "";
}

.ri-flutter-line:before {
  content: "";
}

.ri-focus-2-fill:before {
  content: "";
}

.ri-focus-2-line:before {
  content: "";
}

.ri-focus-3-fill:before {
  content: "";
}

.ri-focus-3-line:before {
  content: "";
}

.ri-focus-fill:before {
  content: "";
}

.ri-focus-line:before {
  content: "";
}

.ri-foggy-fill:before {
  content: "";
}

.ri-foggy-line:before {
  content: "";
}

.ri-folder-2-fill:before {
  content: "";
}

.ri-folder-2-line:before {
  content: "";
}

.ri-folder-3-fill:before {
  content: "";
}

.ri-folder-3-line:before {
  content: "";
}

.ri-folder-4-fill:before {
  content: "";
}

.ri-folder-4-line:before {
  content: "";
}

.ri-folder-5-fill:before {
  content: "";
}

.ri-folder-5-line:before {
  content: "";
}

.ri-folder-add-fill:before {
  content: "";
}

.ri-folder-add-line:before {
  content: "";
}

.ri-folder-chart-2-fill:before {
  content: "";
}

.ri-folder-chart-2-line:before {
  content: "";
}

.ri-folder-chart-fill:before {
  content: "";
}

.ri-folder-chart-line:before {
  content: "";
}

.ri-folder-download-fill:before {
  content: "";
}

.ri-folder-download-line:before {
  content: "";
}

.ri-folder-fill:before {
  content: "";
}

.ri-folder-forbid-fill:before {
  content: "";
}

.ri-folder-forbid-line:before {
  content: "";
}

.ri-folder-history-fill:before {
  content: "";
}

.ri-folder-history-line:before {
  content: "";
}

.ri-folder-info-fill:before {
  content: "";
}

.ri-folder-info-line:before {
  content: "";
}

.ri-folder-keyhole-fill:before {
  content: "";
}

.ri-folder-keyhole-line:before {
  content: "";
}

.ri-folder-line:before {
  content: "";
}

.ri-folder-lock-fill:before {
  content: "";
}

.ri-folder-lock-line:before {
  content: "";
}

.ri-folder-music-fill:before {
  content: "";
}

.ri-folder-music-line:before {
  content: "";
}

.ri-folder-open-fill:before {
  content: "";
}

.ri-folder-open-line:before {
  content: "";
}

.ri-folder-received-fill:before {
  content: "";
}

.ri-folder-received-line:before {
  content: "";
}

.ri-folder-reduce-fill:before {
  content: "";
}

.ri-folder-reduce-line:before {
  content: "";
}

.ri-folder-settings-fill:before {
  content: "";
}

.ri-folder-settings-line:before {
  content: "";
}

.ri-folder-shared-fill:before {
  content: "";
}

.ri-folder-shared-line:before {
  content: "";
}

.ri-folder-shield-2-fill:before {
  content: "";
}

.ri-folder-shield-2-line:before {
  content: "";
}

.ri-folder-shield-fill:before {
  content: "";
}

.ri-folder-shield-line:before {
  content: "";
}

.ri-folder-transfer-fill:before {
  content: "";
}

.ri-folder-transfer-line:before {
  content: "";
}

.ri-folder-unknow-fill:before {
  content: "";
}

.ri-folder-unknow-line:before {
  content: "";
}

.ri-folder-upload-fill:before {
  content: "";
}

.ri-folder-upload-line:before {
  content: "";
}

.ri-folder-user-fill:before {
  content: "";
}

.ri-folder-user-line:before {
  content: "";
}

.ri-folder-warning-fill:before {
  content: "";
}

.ri-folder-warning-line:before {
  content: "";
}

.ri-folder-zip-fill:before {
  content: "";
}

.ri-folder-zip-line:before {
  content: "";
}

.ri-folders-fill:before {
  content: "";
}

.ri-folders-line:before {
  content: "";
}

.ri-font-color:before {
  content: "";
}

.ri-font-size-2:before {
  content: "";
}

.ri-font-size:before {
  content: "";
}

.ri-football-fill:before {
  content: "";
}

.ri-football-line:before {
  content: "";
}

.ri-footprint-fill:before {
  content: "";
}

.ri-footprint-line:before {
  content: "";
}

.ri-forbid-2-fill:before {
  content: "";
}

.ri-forbid-2-line:before {
  content: "";
}

.ri-forbid-fill:before {
  content: "";
}

.ri-forbid-line:before {
  content: "";
}

.ri-format-clear:before {
  content: "";
}

.ri-fridge-fill:before {
  content: "";
}

.ri-fridge-line:before {
  content: "";
}

.ri-fullscreen-exit-fill:before {
  content: "";
}

.ri-fullscreen-exit-line:before {
  content: "";
}

.ri-fullscreen-fill:before {
  content: "";
}

.ri-fullscreen-line:before {
  content: "";
}

.ri-function-fill:before {
  content: "";
}

.ri-function-line:before {
  content: "";
}

.ri-functions:before {
  content: "";
}

.ri-funds-box-fill:before {
  content: "";
}

.ri-funds-box-line:before {
  content: "";
}

.ri-funds-fill:before {
  content: "";
}

.ri-funds-line:before {
  content: "";
}

.ri-gallery-fill:before {
  content: "";
}

.ri-gallery-line:before {
  content: "";
}

.ri-gallery-upload-fill:before {
  content: "";
}

.ri-gallery-upload-line:before {
  content: "";
}

.ri-game-fill:before {
  content: "";
}

.ri-game-line:before {
  content: "";
}

.ri-gamepad-fill:before {
  content: "";
}

.ri-gamepad-line:before {
  content: "";
}

.ri-gas-station-fill:before {
  content: "";
}

.ri-gas-station-line:before {
  content: "";
}

.ri-gatsby-fill:before {
  content: "";
}

.ri-gatsby-line:before {
  content: "";
}

.ri-genderless-fill:before {
  content: "";
}

.ri-genderless-line:before {
  content: "";
}

.ri-ghost-2-fill:before {
  content: "";
}

.ri-ghost-2-line:before {
  content: "";
}

.ri-ghost-fill:before {
  content: "";
}

.ri-ghost-line:before {
  content: "";
}

.ri-ghost-smile-fill:before {
  content: "";
}

.ri-ghost-smile-line:before {
  content: "";
}

.ri-gift-2-fill:before {
  content: "";
}

.ri-gift-2-line:before {
  content: "";
}

.ri-gift-fill:before {
  content: "";
}

.ri-gift-line:before {
  content: "";
}

.ri-git-branch-fill:before {
  content: "";
}

.ri-git-branch-line:before {
  content: "";
}

.ri-git-commit-fill:before {
  content: "";
}

.ri-git-commit-line:before {
  content: "";
}

.ri-git-merge-fill:before {
  content: "";
}

.ri-git-merge-line:before {
  content: "";
}

.ri-git-pull-request-fill:before {
  content: "";
}

.ri-git-pull-request-line:before {
  content: "";
}

.ri-git-repository-commits-fill:before {
  content: "";
}

.ri-git-repository-commits-line:before {
  content: "";
}

.ri-git-repository-fill:before {
  content: "";
}

.ri-git-repository-line:before {
  content: "";
}

.ri-git-repository-private-fill:before {
  content: "";
}

.ri-git-repository-private-line:before {
  content: "";
}

.ri-github-fill:before {
  content: "";
}

.ri-github-line:before {
  content: "";
}

.ri-gitlab-fill:before {
  content: "";
}

.ri-gitlab-line:before {
  content: "";
}

.ri-global-fill:before {
  content: "";
}

.ri-global-line:before {
  content: "";
}

.ri-globe-fill:before {
  content: "";
}

.ri-globe-line:before {
  content: "";
}

.ri-goblet-fill:before {
  content: "";
}

.ri-goblet-line:before {
  content: "";
}

.ri-google-fill:before {
  content: "";
}

.ri-google-line:before {
  content: "";
}

.ri-google-play-fill:before {
  content: "";
}

.ri-google-play-line:before {
  content: "";
}

.ri-government-fill:before {
  content: "";
}

.ri-government-line:before {
  content: "";
}

.ri-gps-fill:before {
  content: "";
}

.ri-gps-line:before {
  content: "";
}

.ri-gradienter-fill:before {
  content: "";
}

.ri-gradienter-line:before {
  content: "";
}

.ri-grid-fill:before {
  content: "";
}

.ri-grid-line:before {
  content: "";
}

.ri-group-2-fill:before {
  content: "";
}

.ri-group-2-line:before {
  content: "";
}

.ri-group-fill:before {
  content: "";
}

.ri-group-line:before {
  content: "";
}

.ri-guide-fill:before {
  content: "";
}

.ri-guide-line:before {
  content: "";
}

.ri-h-1:before {
  content: "";
}

.ri-h-2:before {
  content: "";
}

.ri-h-3:before {
  content: "";
}

.ri-h-4:before {
  content: "";
}

.ri-h-5:before {
  content: "";
}

.ri-h-6:before {
  content: "";
}

.ri-hail-fill:before {
  content: "";
}

.ri-hail-line:before {
  content: "";
}

.ri-hammer-fill:before {
  content: "";
}

.ri-hammer-line:before {
  content: "";
}

.ri-hand-coin-fill:before {
  content: "";
}

.ri-hand-coin-line:before {
  content: "";
}

.ri-hand-heart-fill:before {
  content: "";
}

.ri-hand-heart-line:before {
  content: "";
}

.ri-hand-sanitizer-fill:before {
  content: "";
}

.ri-hand-sanitizer-line:before {
  content: "";
}

.ri-handbag-fill:before {
  content: "";
}

.ri-handbag-line:before {
  content: "";
}

.ri-hard-drive-2-fill:before {
  content: "";
}

.ri-hard-drive-2-line:before {
  content: "";
}

.ri-hard-drive-fill:before {
  content: "";
}

.ri-hard-drive-line:before {
  content: "";
}

.ri-hashtag:before {
  content: "";
}

.ri-haze-2-fill:before {
  content: "";
}

.ri-haze-2-line:before {
  content: "";
}

.ri-haze-fill:before {
  content: "";
}

.ri-haze-line:before {
  content: "";
}

.ri-hd-fill:before {
  content: "";
}

.ri-hd-line:before {
  content: "";
}

.ri-heading:before {
  content: "";
}

.ri-headphone-fill:before {
  content: "";
}

.ri-headphone-line:before {
  content: "";
}

.ri-health-book-fill:before {
  content: "";
}

.ri-health-book-line:before {
  content: "";
}

.ri-heart-2-fill:before {
  content: "";
}

.ri-heart-2-line:before {
  content: "";
}

.ri-heart-3-fill:before {
  content: "";
}

.ri-heart-3-line:before {
  content: "";
}

.ri-heart-add-fill:before {
  content: "";
}

.ri-heart-add-line:before {
  content: "";
}

.ri-heart-fill:before {
  content: "";
}

.ri-heart-line:before {
  content: "";
}

.ri-heart-pulse-fill:before {
  content: "";
}

.ri-heart-pulse-line:before {
  content: "";
}

.ri-hearts-fill:before {
  content: "";
}

.ri-hearts-line:before {
  content: "";
}

.ri-heavy-showers-fill:before {
  content: "";
}

.ri-heavy-showers-line:before {
  content: "";
}

.ri-history-fill:before {
  content: "";
}

.ri-history-line:before {
  content: "";
}

.ri-home-2-fill:before {
  content: "";
}

.ri-home-2-line:before {
  content: "";
}

.ri-home-3-fill:before {
  content: "";
}

.ri-home-3-line:before {
  content: "";
}

.ri-home-4-fill:before {
  content: "";
}

.ri-home-4-line:before {
  content: "";
}

.ri-home-5-fill:before {
  content: "";
}

.ri-home-5-line:before {
  content: "";
}

.ri-home-6-fill:before {
  content: "";
}

.ri-home-6-line:before {
  content: "";
}

.ri-home-7-fill:before {
  content: "";
}

.ri-home-7-line:before {
  content: "";
}

.ri-home-8-fill:before {
  content: "";
}

.ri-home-8-line:before {
  content: "";
}

.ri-home-fill:before {
  content: "";
}

.ri-home-gear-fill:before {
  content: "";
}

.ri-home-gear-line:before {
  content: "";
}

.ri-home-heart-fill:before {
  content: "";
}

.ri-home-heart-line:before {
  content: "";
}

.ri-home-line:before {
  content: "";
}

.ri-home-smile-2-fill:before {
  content: "";
}

.ri-home-smile-2-line:before {
  content: "";
}

.ri-home-smile-fill:before {
  content: "";
}

.ri-home-smile-line:before {
  content: "";
}

.ri-home-wifi-fill:before {
  content: "";
}

.ri-home-wifi-line:before {
  content: "";
}

.ri-honor-of-kings-fill:before {
  content: "";
}

.ri-honor-of-kings-line:before {
  content: "";
}

.ri-honour-fill:before {
  content: "";
}

.ri-honour-line:before {
  content: "";
}

.ri-hospital-fill:before {
  content: "";
}

.ri-hospital-line:before {
  content: "";
}

.ri-hotel-bed-fill:before {
  content: "";
}

.ri-hotel-bed-line:before {
  content: "";
}

.ri-hotel-fill:before {
  content: "";
}

.ri-hotel-line:before {
  content: "";
}

.ri-hotspot-fill:before {
  content: "";
}

.ri-hotspot-line:before {
  content: "";
}

.ri-hq-fill:before {
  content: "";
}

.ri-hq-line:before {
  content: "";
}

.ri-html5-fill:before {
  content: "";
}

.ri-html5-line:before {
  content: "";
}

.ri-ie-fill:before {
  content: "";
}

.ri-ie-line:before {
  content: "";
}

.ri-image-2-fill:before {
  content: "";
}

.ri-image-2-line:before {
  content: "";
}

.ri-image-add-fill:before {
  content: "";
}

.ri-image-add-line:before {
  content: "";
}

.ri-image-edit-fill:before {
  content: "";
}

.ri-image-edit-line:before {
  content: "";
}

.ri-image-fill:before {
  content: "";
}

.ri-image-line:before {
  content: "";
}

.ri-inbox-archive-fill:before {
  content: "";
}

.ri-inbox-archive-line:before {
  content: "";
}

.ri-inbox-fill:before {
  content: "";
}

.ri-inbox-line:before {
  content: "";
}

.ri-inbox-unarchive-fill:before {
  content: "";
}

.ri-inbox-unarchive-line:before {
  content: "";
}

.ri-increase-decrease-fill:before {
  content: "";
}

.ri-increase-decrease-line:before {
  content: "";
}

.ri-indent-decrease:before {
  content: "";
}

.ri-indent-increase:before {
  content: "";
}

.ri-indeterminate-circle-fill:before {
  content: "";
}

.ri-indeterminate-circle-line:before {
  content: "";
}

.ri-information-fill:before {
  content: "";
}

.ri-information-line:before {
  content: "";
}

.ri-infrared-thermometer-fill:before {
  content: "";
}

.ri-infrared-thermometer-line:before {
  content: "";
}

.ri-ink-bottle-fill:before {
  content: "";
}

.ri-ink-bottle-line:before {
  content: "";
}

.ri-input-cursor-move:before {
  content: "";
}

.ri-input-method-fill:before {
  content: "";
}

.ri-input-method-line:before {
  content: "";
}

.ri-insert-column-left:before {
  content: "";
}

.ri-insert-column-right:before {
  content: "";
}

.ri-insert-row-bottom:before {
  content: "";
}

.ri-insert-row-top:before {
  content: "";
}

.ri-instagram-fill:before {
  content: "";
}

.ri-instagram-line:before {
  content: "";
}

.ri-install-fill:before {
  content: "";
}

.ri-install-line:before {
  content: "";
}

.ri-invision-fill:before {
  content: "";
}

.ri-invision-line:before {
  content: "";
}

.ri-italic:before {
  content: "";
}

.ri-kakao-talk-fill:before {
  content: "";
}

.ri-kakao-talk-line:before {
  content: "";
}

.ri-key-2-fill:before {
  content: "";
}

.ri-key-2-line:before {
  content: "";
}

.ri-key-fill:before {
  content: "";
}

.ri-key-line:before {
  content: "";
}

.ri-keyboard-box-fill:before {
  content: "";
}

.ri-keyboard-box-line:before {
  content: "";
}

.ri-keyboard-fill:before {
  content: "";
}

.ri-keyboard-line:before {
  content: "";
}

.ri-keynote-fill:before {
  content: "";
}

.ri-keynote-line:before {
  content: "";
}

.ri-knife-blood-fill:before {
  content: "";
}

.ri-knife-blood-line:before {
  content: "";
}

.ri-knife-fill:before {
  content: "";
}

.ri-knife-line:before {
  content: "";
}

.ri-landscape-fill:before {
  content: "";
}

.ri-landscape-line:before {
  content: "";
}

.ri-layout-2-fill:before {
  content: "";
}

.ri-layout-2-line:before {
  content: "";
}

.ri-layout-3-fill:before {
  content: "";
}

.ri-layout-3-line:before {
  content: "";
}

.ri-layout-4-fill:before {
  content: "";
}

.ri-layout-4-line:before {
  content: "";
}

.ri-layout-5-fill:before {
  content: "";
}

.ri-layout-5-line:before {
  content: "";
}

.ri-layout-6-fill:before {
  content: "";
}

.ri-layout-6-line:before {
  content: "";
}

.ri-layout-bottom-2-fill:before {
  content: "";
}

.ri-layout-bottom-2-line:before {
  content: "";
}

.ri-layout-bottom-fill:before {
  content: "";
}

.ri-layout-bottom-line:before {
  content: "";
}

.ri-layout-column-fill:before {
  content: "";
}

.ri-layout-column-line:before {
  content: "";
}

.ri-layout-fill:before {
  content: "";
}

.ri-layout-grid-fill:before {
  content: "";
}

.ri-layout-grid-line:before {
  content: "";
}

.ri-layout-left-2-fill:before {
  content: "";
}

.ri-layout-left-2-line:before {
  content: "";
}

.ri-layout-left-fill:before {
  content: "";
}

.ri-layout-left-line:before {
  content: "";
}

.ri-layout-line:before {
  content: "";
}

.ri-layout-masonry-fill:before {
  content: "";
}

.ri-layout-masonry-line:before {
  content: "";
}

.ri-layout-right-2-fill:before {
  content: "";
}

.ri-layout-right-2-line:before {
  content: "";
}

.ri-layout-right-fill:before {
  content: "";
}

.ri-layout-right-line:before {
  content: "";
}

.ri-layout-row-fill:before {
  content: "";
}

.ri-layout-row-line:before {
  content: "";
}

.ri-layout-top-2-fill:before {
  content: "";
}

.ri-layout-top-2-line:before {
  content: "";
}

.ri-layout-top-fill:before {
  content: "";
}

.ri-layout-top-line:before {
  content: "";
}

.ri-leaf-fill:before {
  content: "";
}

.ri-leaf-line:before {
  content: "";
}

.ri-lifebuoy-fill:before {
  content: "";
}

.ri-lifebuoy-line:before {
  content: "";
}

.ri-lightbulb-fill:before {
  content: "";
}

.ri-lightbulb-flash-fill:before {
  content: "";
}

.ri-lightbulb-flash-line:before {
  content: "";
}

.ri-lightbulb-line:before {
  content: "";
}

.ri-line-chart-fill:before {
  content: "";
}

.ri-line-chart-line:before {
  content: "";
}

.ri-line-fill:before {
  content: "";
}

.ri-line-height:before {
  content: "";
}

.ri-line-line:before {
  content: "";
}

.ri-link-m:before {
  content: "";
}

.ri-link-unlink-m:before {
  content: "";
}

.ri-link-unlink:before {
  content: "";
}

.ri-link:before {
  content: "";
}

.ri-linkedin-box-fill:before {
  content: "";
}

.ri-linkedin-box-line:before {
  content: "";
}

.ri-linkedin-fill:before {
  content: "";
}

.ri-linkedin-line:before {
  content: "";
}

.ri-links-fill:before {
  content: "";
}

.ri-links-line:before {
  content: "";
}

.ri-list-check-2:before {
  content: "";
}

.ri-list-check:before {
  content: "";
}

.ri-list-ordered:before {
  content: "";
}

.ri-list-settings-fill:before {
  content: "";
}

.ri-list-settings-line:before {
  content: "";
}

.ri-list-unordered:before {
  content: "";
}

.ri-live-fill:before {
  content: "";
}

.ri-live-line:before {
  content: "";
}

.ri-loader-2-fill:before {
  content: "";
}

.ri-loader-2-line:before {
  content: "";
}

.ri-loader-3-fill:before {
  content: "";
}

.ri-loader-3-line:before {
  content: "";
}

.ri-loader-4-fill:before {
  content: "";
}

.ri-loader-4-line:before {
  content: "";
}

.ri-loader-5-fill:before {
  content: "";
}

.ri-loader-5-line:before {
  content: "";
}

.ri-loader-fill:before {
  content: "";
}

.ri-loader-line:before {
  content: "";
}

.ri-lock-2-fill:before {
  content: "";
}

.ri-lock-2-line:before {
  content: "";
}

.ri-lock-fill:before {
  content: "";
}

.ri-lock-line:before {
  content: "";
}

.ri-lock-password-fill:before {
  content: "";
}

.ri-lock-password-line:before {
  content: "";
}

.ri-lock-unlock-fill:before {
  content: "";
}

.ri-lock-unlock-line:before {
  content: "";
}

.ri-login-box-fill:before {
  content: "";
}

.ri-login-box-line:before {
  content: "";
}

.ri-login-circle-fill:before {
  content: "";
}

.ri-login-circle-line:before {
  content: "";
}

.ri-logout-box-fill:before {
  content: "";
}

.ri-logout-box-line:before {
  content: "";
}

.ri-logout-box-r-fill:before {
  content: "";
}

.ri-logout-box-r-line:before {
  content: "";
}

.ri-logout-circle-fill:before {
  content: "";
}

.ri-logout-circle-line:before {
  content: "";
}

.ri-logout-circle-r-fill:before {
  content: "";
}

.ri-logout-circle-r-line:before {
  content: "";
}

.ri-luggage-cart-fill:before {
  content: "";
}

.ri-luggage-cart-line:before {
  content: "";
}

.ri-luggage-deposit-fill:before {
  content: "";
}

.ri-luggage-deposit-line:before {
  content: "";
}

.ri-lungs-fill:before {
  content: "";
}

.ri-lungs-line:before {
  content: "";
}

.ri-mac-fill:before {
  content: "";
}

.ri-mac-line:before {
  content: "";
}

.ri-macbook-fill:before {
  content: "";
}

.ri-macbook-line:before {
  content: "";
}

.ri-magic-fill:before {
  content: "";
}

.ri-magic-line:before {
  content: "";
}

.ri-mail-add-fill:before {
  content: "";
}

.ri-mail-add-line:before {
  content: "";
}

.ri-mail-check-fill:before {
  content: "";
}

.ri-mail-check-line:before {
  content: "";
}

.ri-mail-close-fill:before {
  content: "";
}

.ri-mail-close-line:before {
  content: "";
}

.ri-mail-download-fill:before {
  content: "";
}

.ri-mail-download-line:before {
  content: "";
}

.ri-mail-fill:before {
  content: "";
}

.ri-mail-forbid-fill:before {
  content: "";
}

.ri-mail-forbid-line:before {
  content: "";
}

.ri-mail-line:before {
  content: "";
}

.ri-mail-lock-fill:before {
  content: "";
}

.ri-mail-lock-line:before {
  content: "";
}

.ri-mail-open-fill:before {
  content: "";
}

.ri-mail-open-line:before {
  content: "";
}

.ri-mail-send-fill:before {
  content: "";
}

.ri-mail-send-line:before {
  content: "";
}

.ri-mail-settings-fill:before {
  content: "";
}

.ri-mail-settings-line:before {
  content: "";
}

.ri-mail-star-fill:before {
  content: "";
}

.ri-mail-star-line:before {
  content: "";
}

.ri-mail-unread-fill:before {
  content: "";
}

.ri-mail-unread-line:before {
  content: "";
}

.ri-mail-volume-fill:before {
  content: "";
}

.ri-mail-volume-line:before {
  content: "";
}

.ri-map-2-fill:before {
  content: "";
}

.ri-map-2-line:before {
  content: "";
}

.ri-map-fill:before {
  content: "";
}

.ri-map-line:before {
  content: "";
}

.ri-map-pin-2-fill:before {
  content: "";
}

.ri-map-pin-2-line:before {
  content: "";
}

.ri-map-pin-3-fill:before {
  content: "";
}

.ri-map-pin-3-line:before {
  content: "";
}

.ri-map-pin-4-fill:before {
  content: "";
}

.ri-map-pin-4-line:before {
  content: "";
}

.ri-map-pin-5-fill:before {
  content: "";
}

.ri-map-pin-5-line:before {
  content: "";
}

.ri-map-pin-add-fill:before {
  content: "";
}

.ri-map-pin-add-line:before {
  content: "";
}

.ri-map-pin-fill:before {
  content: "";
}

.ri-map-pin-line:before {
  content: "";
}

.ri-map-pin-range-fill:before {
  content: "";
}

.ri-map-pin-range-line:before {
  content: "";
}

.ri-map-pin-time-fill:before {
  content: "";
}

.ri-map-pin-time-line:before {
  content: "";
}

.ri-map-pin-user-fill:before {
  content: "";
}

.ri-map-pin-user-line:before {
  content: "";
}

.ri-mark-pen-fill:before {
  content: "";
}

.ri-mark-pen-line:before {
  content: "";
}

.ri-markdown-fill:before {
  content: "";
}

.ri-markdown-line:before {
  content: "";
}

.ri-markup-fill:before {
  content: "";
}

.ri-markup-line:before {
  content: "";
}

.ri-mastercard-fill:before {
  content: "";
}

.ri-mastercard-line:before {
  content: "";
}

.ri-mastodon-fill:before {
  content: "";
}

.ri-mastodon-line:before {
  content: "";
}

.ri-medal-2-fill:before {
  content: "";
}

.ri-medal-2-line:before {
  content: "";
}

.ri-medal-fill:before {
  content: "";
}

.ri-medal-line:before {
  content: "";
}

.ri-medicine-bottle-fill:before {
  content: "";
}

.ri-medicine-bottle-line:before {
  content: "";
}

.ri-medium-fill:before {
  content: "";
}

.ri-medium-line:before {
  content: "";
}

.ri-men-fill:before {
  content: "";
}

.ri-men-line:before {
  content: "";
}

.ri-mental-health-fill:before {
  content: "";
}

.ri-mental-health-line:before {
  content: "";
}

.ri-menu-2-fill:before {
  content: "";
}

.ri-menu-2-line:before {
  content: "";
}

.ri-menu-3-fill:before {
  content: "";
}

.ri-menu-3-line:before {
  content: "";
}

.ri-menu-4-fill:before {
  content: "";
}

.ri-menu-4-line:before {
  content: "";
}

.ri-menu-5-fill:before {
  content: "";
}

.ri-menu-5-line:before {
  content: "";
}

.ri-menu-add-fill:before {
  content: "";
}

.ri-menu-add-line:before {
  content: "";
}

.ri-menu-fill:before {
  content: "";
}

.ri-menu-fold-fill:before {
  content: "";
}

.ri-menu-fold-line:before {
  content: "";
}

.ri-menu-line:before {
  content: "";
}

.ri-menu-unfold-fill:before {
  content: "";
}

.ri-menu-unfold-line:before {
  content: "";
}

.ri-merge-cells-horizontal:before {
  content: "";
}

.ri-merge-cells-vertical:before {
  content: "";
}

.ri-message-2-fill:before {
  content: "";
}

.ri-message-2-line:before {
  content: "";
}

.ri-message-3-fill:before {
  content: "";
}

.ri-message-3-line:before {
  content: "";
}

.ri-message-fill:before {
  content: "";
}

.ri-message-line:before {
  content: "";
}

.ri-messenger-fill:before {
  content: "";
}

.ri-messenger-line:before {
  content: "";
}

.ri-meteor-fill:before {
  content: "";
}

.ri-meteor-line:before {
  content: "";
}

.ri-mic-2-fill:before {
  content: "";
}

.ri-mic-2-line:before {
  content: "";
}

.ri-mic-fill:before {
  content: "";
}

.ri-mic-line:before {
  content: "";
}

.ri-mic-off-fill:before {
  content: "";
}

.ri-mic-off-line:before {
  content: "";
}

.ri-mickey-fill:before {
  content: "";
}

.ri-mickey-line:before {
  content: "";
}

.ri-microscope-fill:before {
  content: "";
}

.ri-microscope-line:before {
  content: "";
}

.ri-microsoft-fill:before {
  content: "";
}

.ri-microsoft-line:before {
  content: "";
}

.ri-mind-map:before {
  content: "";
}

.ri-mini-program-fill:before {
  content: "";
}

.ri-mini-program-line:before {
  content: "";
}

.ri-mist-fill:before {
  content: "";
}

.ri-mist-line:before {
  content: "";
}

.ri-money-cny-box-fill:before {
  content: "";
}

.ri-money-cny-box-line:before {
  content: "";
}

.ri-money-cny-circle-fill:before {
  content: "";
}

.ri-money-cny-circle-line:before {
  content: "";
}

.ri-money-dollar-box-fill:before {
  content: "";
}

.ri-money-dollar-box-line:before {
  content: "";
}

.ri-money-dollar-circle-fill:before {
  content: "";
}

.ri-money-dollar-circle-line:before {
  content: "";
}

.ri-money-euro-box-fill:before {
  content: "";
}

.ri-money-euro-box-line:before {
  content: "";
}

.ri-money-euro-circle-fill:before {
  content: "";
}

.ri-money-euro-circle-line:before {
  content: "";
}

.ri-money-pound-box-fill:before {
  content: "";
}

.ri-money-pound-box-line:before {
  content: "";
}

.ri-money-pound-circle-fill:before {
  content: "";
}

.ri-money-pound-circle-line:before {
  content: "";
}

.ri-moon-clear-fill:before {
  content: "";
}

.ri-moon-clear-line:before {
  content: "";
}

.ri-moon-cloudy-fill:before {
  content: "";
}

.ri-moon-cloudy-line:before {
  content: "";
}

.ri-moon-fill:before {
  content: "";
}

.ri-moon-foggy-fill:before {
  content: "";
}

.ri-moon-foggy-line:before {
  content: "";
}

.ri-moon-line:before {
  content: "";
}

.ri-more-2-fill:before {
  content: "";
}

.ri-more-2-line:before {
  content: "";
}

.ri-more-fill:before {
  content: "";
}

.ri-more-line:before {
  content: "";
}

.ri-motorbike-fill:before {
  content: "";
}

.ri-motorbike-line:before {
  content: "";
}

.ri-mouse-fill:before {
  content: "";
}

.ri-mouse-line:before {
  content: "";
}

.ri-movie-2-fill:before {
  content: "";
}

.ri-movie-2-line:before {
  content: "";
}

.ri-movie-fill:before {
  content: "";
}

.ri-movie-line:before {
  content: "";
}

.ri-music-2-fill:before {
  content: "";
}

.ri-music-2-line:before {
  content: "";
}

.ri-music-fill:before {
  content: "";
}

.ri-music-line:before {
  content: "";
}

.ri-mv-fill:before {
  content: "";
}

.ri-mv-line:before {
  content: "";
}

.ri-navigation-fill:before {
  content: "";
}

.ri-navigation-line:before {
  content: "";
}

.ri-netease-cloud-music-fill:before {
  content: "";
}

.ri-netease-cloud-music-line:before {
  content: "";
}

.ri-netflix-fill:before {
  content: "";
}

.ri-netflix-line:before {
  content: "";
}

.ri-newspaper-fill:before {
  content: "";
}

.ri-newspaper-line:before {
  content: "";
}

.ri-node-tree:before {
  content: "";
}

.ri-notification-2-fill:before {
  content: "";
}

.ri-notification-2-line:before {
  content: "";
}

.ri-notification-3-fill:before {
  content: "";
}

.ri-notification-3-line:before {
  content: "";
}

.ri-notification-4-fill:before {
  content: "";
}

.ri-notification-4-line:before {
  content: "";
}

.ri-notification-badge-fill:before {
  content: "";
}

.ri-notification-badge-line:before {
  content: "";
}

.ri-notification-fill:before {
  content: "";
}

.ri-notification-line:before {
  content: "";
}

.ri-notification-off-fill:before {
  content: "";
}

.ri-notification-off-line:before {
  content: "";
}

.ri-npmjs-fill:before {
  content: "";
}

.ri-npmjs-line:before {
  content: "";
}

.ri-number-0:before {
  content: "";
}

.ri-number-1:before {
  content: "";
}

.ri-number-2:before {
  content: "";
}

.ri-number-3:before {
  content: "";
}

.ri-number-4:before {
  content: "";
}

.ri-number-5:before {
  content: "";
}

.ri-number-6:before {
  content: "";
}

.ri-number-7:before {
  content: "";
}

.ri-number-8:before {
  content: "";
}

.ri-number-9:before {
  content: "";
}

.ri-numbers-fill:before {
  content: "";
}

.ri-numbers-line:before {
  content: "";
}

.ri-nurse-fill:before {
  content: "";
}

.ri-nurse-line:before {
  content: "";
}

.ri-oil-fill:before {
  content: "";
}

.ri-oil-line:before {
  content: "";
}

.ri-omega:before {
  content: "";
}

.ri-open-arm-fill:before {
  content: "";
}

.ri-open-arm-line:before {
  content: "";
}

.ri-open-source-fill:before {
  content: "";
}

.ri-open-source-line:before {
  content: "";
}

.ri-opera-fill:before {
  content: "";
}

.ri-opera-line:before {
  content: "";
}

.ri-order-play-fill:before {
  content: "";
}

.ri-order-play-line:before {
  content: "";
}

.ri-organization-chart:before {
  content: "";
}

.ri-outlet-2-fill:before {
  content: "";
}

.ri-outlet-2-line:before {
  content: "";
}

.ri-outlet-fill:before {
  content: "";
}

.ri-outlet-line:before {
  content: "";
}

.ri-page-separator:before {
  content: "";
}

.ri-pages-fill:before {
  content: "";
}

.ri-pages-line:before {
  content: "";
}

.ri-paint-brush-fill:before {
  content: "";
}

.ri-paint-brush-line:before {
  content: "";
}

.ri-paint-fill:before {
  content: "";
}

.ri-paint-line:before {
  content: "";
}

.ri-palette-fill:before {
  content: "";
}

.ri-palette-line:before {
  content: "";
}

.ri-pantone-fill:before {
  content: "";
}

.ri-pantone-line:before {
  content: "";
}

.ri-paragraph:before {
  content: "";
}

.ri-parent-fill:before {
  content: "";
}

.ri-parent-line:before {
  content: "";
}

.ri-parentheses-fill:before {
  content: "";
}

.ri-parentheses-line:before {
  content: "";
}

.ri-parking-box-fill:before {
  content: "";
}

.ri-parking-box-line:before {
  content: "";
}

.ri-parking-fill:before {
  content: "";
}

.ri-parking-line:before {
  content: "";
}

.ri-passport-fill:before {
  content: "";
}

.ri-passport-line:before {
  content: "";
}

.ri-patreon-fill:before {
  content: "";
}

.ri-patreon-line:before {
  content: "";
}

.ri-pause-circle-fill:before {
  content: "";
}

.ri-pause-circle-line:before {
  content: "";
}

.ri-pause-fill:before {
  content: "";
}

.ri-pause-line:before {
  content: "";
}

.ri-pause-mini-fill:before {
  content: "";
}

.ri-pause-mini-line:before {
  content: "";
}

.ri-paypal-fill:before {
  content: "";
}

.ri-paypal-line:before {
  content: "";
}

.ri-pen-nib-fill:before {
  content: "";
}

.ri-pen-nib-line:before {
  content: "";
}

.ri-pencil-fill:before {
  content: "";
}

.ri-pencil-line:before {
  content: "";
}

.ri-pencil-ruler-2-fill:before {
  content: "";
}

.ri-pencil-ruler-2-line:before {
  content: "";
}

.ri-pencil-ruler-fill:before {
  content: "";
}

.ri-pencil-ruler-line:before {
  content: "";
}

.ri-percent-fill:before {
  content: "";
}

.ri-percent-line:before {
  content: "";
}

.ri-phone-camera-fill:before {
  content: "";
}

.ri-phone-camera-line:before {
  content: "";
}

.ri-phone-fill:before {
  content: "";
}

.ri-phone-find-fill:before {
  content: "";
}

.ri-phone-find-line:before {
  content: "";
}

.ri-phone-line:before {
  content: "";
}

.ri-phone-lock-fill:before {
  content: "";
}

.ri-phone-lock-line:before {
  content: "";
}

.ri-picture-in-picture-2-fill:before {
  content: "";
}

.ri-picture-in-picture-2-line:before {
  content: "";
}

.ri-picture-in-picture-exit-fill:before {
  content: "";
}

.ri-picture-in-picture-exit-line:before {
  content: "";
}

.ri-picture-in-picture-fill:before {
  content: "";
}

.ri-picture-in-picture-line:before {
  content: "";
}

.ri-pie-chart-2-fill:before {
  content: "";
}

.ri-pie-chart-2-line:before {
  content: "";
}

.ri-pie-chart-box-fill:before {
  content: "";
}

.ri-pie-chart-box-line:before {
  content: "";
}

.ri-pie-chart-fill:before {
  content: "";
}

.ri-pie-chart-line:before {
  content: "";
}

.ri-pin-distance-fill:before {
  content: "";
}

.ri-pin-distance-line:before {
  content: "";
}

.ri-ping-pong-fill:before {
  content: "";
}

.ri-ping-pong-line:before {
  content: "";
}

.ri-pinterest-fill:before {
  content: "";
}

.ri-pinterest-line:before {
  content: "";
}

.ri-pinyin-input:before {
  content: "";
}

.ri-pixelfed-fill:before {
  content: "";
}

.ri-pixelfed-line:before {
  content: "";
}

.ri-plane-fill:before {
  content: "";
}

.ri-plane-line:before {
  content: "";
}

.ri-plant-fill:before {
  content: "";
}

.ri-plant-line:before {
  content: "";
}

.ri-play-circle-fill:before {
  content: "";
}

.ri-play-circle-line:before {
  content: "";
}

.ri-play-fill:before {
  content: "";
}

.ri-play-line:before {
  content: "";
}

.ri-play-list-2-fill:before {
  content: "";
}

.ri-play-list-2-line:before {
  content: "";
}

.ri-play-list-add-fill:before {
  content: "";
}

.ri-play-list-add-line:before {
  content: "";
}

.ri-play-list-fill:before {
  content: "";
}

.ri-play-list-line:before {
  content: "";
}

.ri-play-mini-fill:before {
  content: "";
}

.ri-play-mini-line:before {
  content: "";
}

.ri-playstation-fill:before {
  content: "";
}

.ri-playstation-line:before {
  content: "";
}

.ri-plug-2-fill:before {
  content: "";
}

.ri-plug-2-line:before {
  content: "";
}

.ri-plug-fill:before {
  content: "";
}

.ri-plug-line:before {
  content: "";
}

.ri-polaroid-2-fill:before {
  content: "";
}

.ri-polaroid-2-line:before {
  content: "";
}

.ri-polaroid-fill:before {
  content: "";
}

.ri-polaroid-line:before {
  content: "";
}

.ri-police-car-fill:before {
  content: "";
}

.ri-police-car-line:before {
  content: "";
}

.ri-price-tag-2-fill:before {
  content: "";
}

.ri-price-tag-2-line:before {
  content: "";
}

.ri-price-tag-3-fill:before {
  content: "";
}

.ri-price-tag-3-line:before {
  content: "";
}

.ri-price-tag-fill:before {
  content: "";
}

.ri-price-tag-line:before {
  content: "";
}

.ri-printer-cloud-fill:before {
  content: "";
}

.ri-printer-cloud-line:before {
  content: "";
}

.ri-printer-fill:before {
  content: "";
}

.ri-printer-line:before {
  content: "";
}

.ri-product-hunt-fill:before {
  content: "";
}

.ri-product-hunt-line:before {
  content: "";
}

.ri-profile-fill:before {
  content: "";
}

.ri-profile-line:before {
  content: "";
}

.ri-projector-2-fill:before {
  content: "";
}

.ri-projector-2-line:before {
  content: "";
}

.ri-projector-fill:before {
  content: "";
}

.ri-projector-line:before {
  content: "";
}

.ri-psychotherapy-fill:before {
  content: "";
}

.ri-psychotherapy-line:before {
  content: "";
}

.ri-pulse-fill:before {
  content: "";
}

.ri-pulse-line:before {
  content: "";
}

.ri-pushpin-2-fill:before {
  content: "";
}

.ri-pushpin-2-line:before {
  content: "";
}

.ri-pushpin-fill:before {
  content: "";
}

.ri-pushpin-line:before {
  content: "";
}

.ri-qq-fill:before {
  content: "";
}

.ri-qq-line:before {
  content: "";
}

.ri-qr-code-fill:before {
  content: "";
}

.ri-qr-code-line:before {
  content: "";
}

.ri-qr-scan-2-fill:before {
  content: "";
}

.ri-qr-scan-2-line:before {
  content: "";
}

.ri-qr-scan-fill:before {
  content: "";
}

.ri-qr-scan-line:before {
  content: "";
}

.ri-question-answer-fill:before {
  content: "";
}

.ri-question-answer-line:before {
  content: "";
}

.ri-question-fill:before {
  content: "";
}

.ri-question-line:before {
  content: "";
}

.ri-question-mark:before {
  content: "";
}

.ri-questionnaire-fill:before {
  content: "";
}

.ri-questionnaire-line:before {
  content: "";
}

.ri-quill-pen-fill:before {
  content: "";
}

.ri-quill-pen-line:before {
  content: "";
}

.ri-radar-fill:before {
  content: "";
}

.ri-radar-line:before {
  content: "";
}

.ri-radio-2-fill:before {
  content: "";
}

.ri-radio-2-line:before {
  content: "";
}

.ri-radio-button-fill:before {
  content: "";
}

.ri-radio-button-line:before {
  content: "";
}

.ri-radio-fill:before {
  content: "";
}

.ri-radio-line:before {
  content: "";
}

.ri-rainbow-fill:before {
  content: "";
}

.ri-rainbow-line:before {
  content: "";
}

.ri-rainy-fill:before {
  content: "";
}

.ri-rainy-line:before {
  content: "";
}

.ri-reactjs-fill:before {
  content: "";
}

.ri-reactjs-line:before {
  content: "";
}

.ri-record-circle-fill:before {
  content: "";
}

.ri-record-circle-line:before {
  content: "";
}

.ri-record-mail-fill:before {
  content: "";
}

.ri-record-mail-line:before {
  content: "";
}

.ri-recycle-fill:before {
  content: "";
}

.ri-recycle-line:before {
  content: "";
}

.ri-red-packet-fill:before {
  content: "";
}

.ri-red-packet-line:before {
  content: "";
}

.ri-reddit-fill:before {
  content: "";
}

.ri-reddit-line:before {
  content: "";
}

.ri-refresh-fill:before {
  content: "";
}

.ri-refresh-line:before {
  content: "";
}

.ri-refund-2-fill:before {
  content: "";
}

.ri-refund-2-line:before {
  content: "";
}

.ri-refund-fill:before {
  content: "";
}

.ri-refund-line:before {
  content: "";
}

.ri-registered-fill:before {
  content: "";
}

.ri-registered-line:before {
  content: "";
}

.ri-remixicon-fill:before {
  content: "";
}

.ri-remixicon-line:before {
  content: "";
}

.ri-remote-control-2-fill:before {
  content: "";
}

.ri-remote-control-2-line:before {
  content: "";
}

.ri-remote-control-fill:before {
  content: "";
}

.ri-remote-control-line:before {
  content: "";
}

.ri-repeat-2-fill:before {
  content: "";
}

.ri-repeat-2-line:before {
  content: "";
}

.ri-repeat-fill:before {
  content: "";
}

.ri-repeat-line:before {
  content: "";
}

.ri-repeat-one-fill:before {
  content: "";
}

.ri-repeat-one-line:before {
  content: "";
}

.ri-reply-all-fill:before {
  content: "";
}

.ri-reply-all-line:before {
  content: "";
}

.ri-reply-fill:before {
  content: "";
}

.ri-reply-line:before {
  content: "";
}

.ri-reserved-fill:before {
  content: "";
}

.ri-reserved-line:before {
  content: "";
}

.ri-rest-time-fill:before {
  content: "";
}

.ri-rest-time-line:before {
  content: "";
}

.ri-restart-fill:before {
  content: "";
}

.ri-restart-line:before {
  content: "";
}

.ri-restaurant-2-fill:before {
  content: "";
}

.ri-restaurant-2-line:before {
  content: "";
}

.ri-restaurant-fill:before {
  content: "";
}

.ri-restaurant-line:before {
  content: "";
}

.ri-rewind-fill:before {
  content: "";
}

.ri-rewind-line:before {
  content: "";
}

.ri-rewind-mini-fill:before {
  content: "";
}

.ri-rewind-mini-line:before {
  content: "";
}

.ri-rhythm-fill:before {
  content: "";
}

.ri-rhythm-line:before {
  content: "";
}

.ri-riding-fill:before {
  content: "";
}

.ri-riding-line:before {
  content: "";
}

.ri-road-map-fill:before {
  content: "";
}

.ri-road-map-line:before {
  content: "";
}

.ri-roadster-fill:before {
  content: "";
}

.ri-roadster-line:before {
  content: "";
}

.ri-robot-fill:before {
  content: "";
}

.ri-robot-line:before {
  content: "";
}

.ri-rocket-2-fill:before {
  content: "";
}

.ri-rocket-2-line:before {
  content: "";
}

.ri-rocket-fill:before {
  content: "";
}

.ri-rocket-line:before {
  content: "";
}

.ri-rotate-lock-fill:before {
  content: "";
}

.ri-rotate-lock-line:before {
  content: "";
}

.ri-rounded-corner:before {
  content: "";
}

.ri-route-fill:before {
  content: "";
}

.ri-route-line:before {
  content: "";
}

.ri-router-fill:before {
  content: "";
}

.ri-router-line:before {
  content: "";
}

.ri-rss-fill:before {
  content: "";
}

.ri-rss-line:before {
  content: "";
}

.ri-ruler-2-fill:before {
  content: "";
}

.ri-ruler-2-line:before {
  content: "";
}

.ri-ruler-fill:before {
  content: "";
}

.ri-ruler-line:before {
  content: "";
}

.ri-run-fill:before {
  content: "";
}

.ri-run-line:before {
  content: "";
}

.ri-safari-fill:before {
  content: "";
}

.ri-safari-line:before {
  content: "";
}

.ri-safe-2-fill:before {
  content: "";
}

.ri-safe-2-line:before {
  content: "";
}

.ri-safe-fill:before {
  content: "";
}

.ri-safe-line:before {
  content: "";
}

.ri-sailboat-fill:before {
  content: "";
}

.ri-sailboat-line:before {
  content: "";
}

.ri-save-2-fill:before {
  content: "";
}

.ri-save-2-line:before {
  content: "";
}

.ri-save-3-fill:before {
  content: "";
}

.ri-save-3-line:before {
  content: "";
}

.ri-save-fill:before {
  content: "";
}

.ri-save-line:before {
  content: "";
}

.ri-scales-2-fill:before {
  content: "";
}

.ri-scales-2-line:before {
  content: "";
}

.ri-scales-3-fill:before {
  content: "";
}

.ri-scales-3-line:before {
  content: "";
}

.ri-scales-fill:before {
  content: "";
}

.ri-scales-line:before {
  content: "";
}

.ri-scan-2-fill:before {
  content: "";
}

.ri-scan-2-line:before {
  content: "";
}

.ri-scan-fill:before {
  content: "";
}

.ri-scan-line:before {
  content: "";
}

.ri-scissors-2-fill:before {
  content: "";
}

.ri-scissors-2-line:before {
  content: "";
}

.ri-scissors-cut-fill:before {
  content: "";
}

.ri-scissors-cut-line:before {
  content: "";
}

.ri-scissors-fill:before {
  content: "";
}

.ri-scissors-line:before {
  content: "";
}

.ri-screenshot-2-fill:before {
  content: "";
}

.ri-screenshot-2-line:before {
  content: "";
}

.ri-screenshot-fill:before {
  content: "";
}

.ri-screenshot-line:before {
  content: "";
}

.ri-sd-card-fill:before {
  content: "";
}

.ri-sd-card-line:before {
  content: "";
}

.ri-sd-card-mini-fill:before {
  content: "";
}

.ri-sd-card-mini-line:before {
  content: "";
}

.ri-search-2-fill:before {
  content: "";
}

.ri-search-2-line:before {
  content: "";
}

.ri-search-eye-fill:before {
  content: "";
}

.ri-search-eye-line:before {
  content: "";
}

.ri-search-fill:before {
  content: "";
}

.ri-search-line:before {
  content: "";
}

.ri-secure-payment-fill:before {
  content: "";
}

.ri-secure-payment-line:before {
  content: "";
}

.ri-seedling-fill:before {
  content: "";
}

.ri-seedling-line:before {
  content: "";
}

.ri-send-backward:before {
  content: "";
}

.ri-send-plane-2-fill:before {
  content: "";
}

.ri-send-plane-2-line:before {
  content: "";
}

.ri-send-plane-fill:before {
  content: "";
}

.ri-send-plane-line:before {
  content: "";
}

.ri-send-to-back:before {
  content: "";
}

.ri-sensor-fill:before {
  content: "";
}

.ri-sensor-line:before {
  content: "";
}

.ri-separator:before {
  content: "";
}

.ri-server-fill:before {
  content: "";
}

.ri-server-line:before {
  content: "";
}

.ri-service-fill:before {
  content: "";
}

.ri-service-line:before {
  content: "";
}

.ri-settings-2-fill:before {
  content: "";
}

.ri-settings-2-line:before {
  content: "";
}

.ri-settings-3-fill:before {
  content: "";
}

.ri-settings-3-line:before {
  content: "";
}

.ri-settings-4-fill:before {
  content: "";
}

.ri-settings-4-line:before {
  content: "";
}

.ri-settings-5-fill:before {
  content: "";
}

.ri-settings-5-line:before {
  content: "";
}

.ri-settings-6-fill:before {
  content: "";
}

.ri-settings-6-line:before {
  content: "";
}

.ri-settings-fill:before {
  content: "";
}

.ri-settings-line:before {
  content: "";
}

.ri-shape-2-fill:before {
  content: "";
}

.ri-shape-2-line:before {
  content: "";
}

.ri-shape-fill:before {
  content: "";
}

.ri-shape-line:before {
  content: "";
}

.ri-share-box-fill:before {
  content: "";
}

.ri-share-box-line:before {
  content: "";
}

.ri-share-circle-fill:before {
  content: "";
}

.ri-share-circle-line:before {
  content: "";
}

.ri-share-fill:before {
  content: "";
}

.ri-share-forward-2-fill:before {
  content: "";
}

.ri-share-forward-2-line:before {
  content: "";
}

.ri-share-forward-box-fill:before {
  content: "";
}

.ri-share-forward-box-line:before {
  content: "";
}

.ri-share-forward-fill:before {
  content: "";
}

.ri-share-forward-line:before {
  content: "";
}

.ri-share-line:before {
  content: "";
}

.ri-shield-check-fill:before {
  content: "";
}

.ri-shield-check-line:before {
  content: "";
}

.ri-shield-cross-fill:before {
  content: "";
}

.ri-shield-cross-line:before {
  content: "";
}

.ri-shield-fill:before {
  content: "";
}

.ri-shield-flash-fill:before {
  content: "";
}

.ri-shield-flash-line:before {
  content: "";
}

.ri-shield-keyhole-fill:before {
  content: "";
}

.ri-shield-keyhole-line:before {
  content: "";
}

.ri-shield-line:before {
  content: "";
}

.ri-shield-star-fill:before {
  content: "";
}

.ri-shield-star-line:before {
  content: "";
}

.ri-shield-user-fill:before {
  content: "";
}

.ri-shield-user-line:before {
  content: "";
}

.ri-ship-2-fill:before {
  content: "";
}

.ri-ship-2-line:before {
  content: "";
}

.ri-ship-fill:before {
  content: "";
}

.ri-ship-line:before {
  content: "";
}

.ri-shirt-fill:before {
  content: "";
}

.ri-shirt-line:before {
  content: "";
}

.ri-shopping-bag-2-fill:before {
  content: "";
}

.ri-shopping-bag-2-line:before {
  content: "";
}

.ri-shopping-bag-3-fill:before {
  content: "";
}

.ri-shopping-bag-3-line:before {
  content: "";
}

.ri-shopping-bag-fill:before {
  content: "";
}

.ri-shopping-bag-line:before {
  content: "";
}

.ri-shopping-basket-2-fill:before {
  content: "";
}

.ri-shopping-basket-2-line:before {
  content: "";
}

.ri-shopping-basket-fill:before {
  content: "";
}

.ri-shopping-basket-line:before {
  content: "";
}

.ri-shopping-cart-2-fill:before {
  content: "";
}

.ri-shopping-cart-2-line:before {
  content: "";
}

.ri-shopping-cart-fill:before {
  content: "";
}

.ri-shopping-cart-line:before {
  content: "";
}

.ri-showers-fill:before {
  content: "";
}

.ri-showers-line:before {
  content: "";
}

.ri-shuffle-fill:before {
  content: "";
}

.ri-shuffle-line:before {
  content: "";
}

.ri-shut-down-fill:before {
  content: "";
}

.ri-shut-down-line:before {
  content: "";
}

.ri-side-bar-fill:before {
  content: "";
}

.ri-side-bar-line:before {
  content: "";
}

.ri-signal-tower-fill:before {
  content: "";
}

.ri-signal-tower-line:before {
  content: "";
}

.ri-signal-wifi-1-fill:before {
  content: "";
}

.ri-signal-wifi-1-line:before {
  content: "";
}

.ri-signal-wifi-2-fill:before {
  content: "";
}

.ri-signal-wifi-2-line:before {
  content: "";
}

.ri-signal-wifi-3-fill:before {
  content: "";
}

.ri-signal-wifi-3-line:before {
  content: "";
}

.ri-signal-wifi-error-fill:before {
  content: "";
}

.ri-signal-wifi-error-line:before {
  content: "";
}

.ri-signal-wifi-fill:before {
  content: "";
}

.ri-signal-wifi-line:before {
  content: "";
}

.ri-signal-wifi-off-fill:before {
  content: "";
}

.ri-signal-wifi-off-line:before {
  content: "";
}

.ri-sim-card-2-fill:before {
  content: "";
}

.ri-sim-card-2-line:before {
  content: "";
}

.ri-sim-card-fill:before {
  content: "";
}

.ri-sim-card-line:before {
  content: "";
}

.ri-single-quotes-l:before {
  content: "";
}

.ri-single-quotes-r:before {
  content: "";
}

.ri-sip-fill:before {
  content: "";
}

.ri-sip-line:before {
  content: "";
}

.ri-skip-back-fill:before {
  content: "";
}

.ri-skip-back-line:before {
  content: "";
}

.ri-skip-back-mini-fill:before {
  content: "";
}

.ri-skip-back-mini-line:before {
  content: "";
}

.ri-skip-forward-fill:before {
  content: "";
}

.ri-skip-forward-line:before {
  content: "";
}

.ri-skip-forward-mini-fill:before {
  content: "";
}

.ri-skip-forward-mini-line:before {
  content: "";
}

.ri-skull-2-fill:before {
  content: "";
}

.ri-skull-2-line:before {
  content: "";
}

.ri-skull-fill:before {
  content: "";
}

.ri-skull-line:before {
  content: "";
}

.ri-skype-fill:before {
  content: "";
}

.ri-skype-line:before {
  content: "";
}

.ri-slack-fill:before {
  content: "";
}

.ri-slack-line:before {
  content: "";
}

.ri-slice-fill:before {
  content: "";
}

.ri-slice-line:before {
  content: "";
}

.ri-slideshow-2-fill:before {
  content: "";
}

.ri-slideshow-2-line:before {
  content: "";
}

.ri-slideshow-3-fill:before {
  content: "";
}

.ri-slideshow-3-line:before {
  content: "";
}

.ri-slideshow-4-fill:before {
  content: "";
}

.ri-slideshow-4-line:before {
  content: "";
}

.ri-slideshow-fill:before {
  content: "";
}

.ri-slideshow-line:before {
  content: "";
}

.ri-smartphone-fill:before {
  content: "";
}

.ri-smartphone-line:before {
  content: "";
}

.ri-snapchat-fill:before {
  content: "";
}

.ri-snapchat-line:before {
  content: "";
}

.ri-snowy-fill:before {
  content: "";
}

.ri-snowy-line:before {
  content: "";
}

.ri-sort-asc:before {
  content: "";
}

.ri-sort-desc:before {
  content: "";
}

.ri-sound-module-fill:before {
  content: "";
}

.ri-sound-module-line:before {
  content: "";
}

.ri-soundcloud-fill:before {
  content: "";
}

.ri-soundcloud-line:before {
  content: "";
}

.ri-space-ship-fill:before {
  content: "";
}

.ri-space-ship-line:before {
  content: "";
}

.ri-space:before {
  content: "";
}

.ri-spam-2-fill:before {
  content: "";
}

.ri-spam-2-line:before {
  content: "";
}

.ri-spam-3-fill:before {
  content: "";
}

.ri-spam-3-line:before {
  content: "";
}

.ri-spam-fill:before {
  content: "";
}

.ri-spam-line:before {
  content: "";
}

.ri-speaker-2-fill:before {
  content: "";
}

.ri-speaker-2-line:before {
  content: "";
}

.ri-speaker-3-fill:before {
  content: "";
}

.ri-speaker-3-line:before {
  content: "";
}

.ri-speaker-fill:before {
  content: "";
}

.ri-speaker-line:before {
  content: "";
}

.ri-spectrum-fill:before {
  content: "";
}

.ri-spectrum-line:before {
  content: "";
}

.ri-speed-fill:before {
  content: "";
}

.ri-speed-line:before {
  content: "";
}

.ri-speed-mini-fill:before {
  content: "";
}

.ri-speed-mini-line:before {
  content: "";
}

.ri-split-cells-horizontal:before {
  content: "";
}

.ri-split-cells-vertical:before {
  content: "";
}

.ri-spotify-fill:before {
  content: "";
}

.ri-spotify-line:before {
  content: "";
}

.ri-spy-fill:before {
  content: "";
}

.ri-spy-line:before {
  content: "";
}

.ri-stack-fill:before {
  content: "";
}

.ri-stack-line:before {
  content: "";
}

.ri-stack-overflow-fill:before {
  content: "";
}

.ri-stack-overflow-line:before {
  content: "";
}

.ri-stackshare-fill:before {
  content: "";
}

.ri-stackshare-line:before {
  content: "";
}

.ri-star-fill:before {
  content: "";
}

.ri-star-half-fill:before {
  content: "";
}

.ri-star-half-line:before {
  content: "";
}

.ri-star-half-s-fill:before {
  content: "";
}

.ri-star-half-s-line:before {
  content: "";
}

.ri-star-line:before {
  content: "";
}

.ri-star-s-fill:before {
  content: "";
}

.ri-star-s-line:before {
  content: "";
}

.ri-star-smile-fill:before {
  content: "";
}

.ri-star-smile-line:before {
  content: "";
}

.ri-steam-fill:before {
  content: "";
}

.ri-steam-line:before {
  content: "";
}

.ri-steering-2-fill:before {
  content: "";
}

.ri-steering-2-line:before {
  content: "";
}

.ri-steering-fill:before {
  content: "";
}

.ri-steering-line:before {
  content: "";
}

.ri-stethoscope-fill:before {
  content: "";
}

.ri-stethoscope-line:before {
  content: "";
}

.ri-sticky-note-2-fill:before {
  content: "";
}

.ri-sticky-note-2-line:before {
  content: "";
}

.ri-sticky-note-fill:before {
  content: "";
}

.ri-sticky-note-line:before {
  content: "";
}

.ri-stock-fill:before {
  content: "";
}

.ri-stock-line:before {
  content: "";
}

.ri-stop-circle-fill:before {
  content: "";
}

.ri-stop-circle-line:before {
  content: "";
}

.ri-stop-fill:before {
  content: "";
}

.ri-stop-line:before {
  content: "";
}

.ri-stop-mini-fill:before {
  content: "";
}

.ri-stop-mini-line:before {
  content: "";
}

.ri-store-2-fill:before {
  content: "";
}

.ri-store-2-line:before {
  content: "";
}

.ri-store-3-fill:before {
  content: "";
}

.ri-store-3-line:before {
  content: "";
}

.ri-store-fill:before {
  content: "";
}

.ri-store-line:before {
  content: "";
}

.ri-strikethrough-2:before {
  content: "";
}

.ri-strikethrough:before {
  content: "";
}

.ri-subscript-2:before {
  content: "";
}

.ri-subscript:before {
  content: "";
}

.ri-subtract-fill:before {
  content: "";
}

.ri-subtract-line:before {
  content: "";
}

.ri-subway-fill:before {
  content: "";
}

.ri-subway-line:before {
  content: "";
}

.ri-subway-wifi-fill:before {
  content: "";
}

.ri-subway-wifi-line:before {
  content: "";
}

.ri-suitcase-2-fill:before {
  content: "";
}

.ri-suitcase-2-line:before {
  content: "";
}

.ri-suitcase-3-fill:before {
  content: "";
}

.ri-suitcase-3-line:before {
  content: "";
}

.ri-suitcase-fill:before {
  content: "";
}

.ri-suitcase-line:before {
  content: "";
}

.ri-sun-cloudy-fill:before {
  content: "";
}

.ri-sun-cloudy-line:before {
  content: "";
}

.ri-sun-fill:before {
  content: "";
}

.ri-sun-foggy-fill:before {
  content: "";
}

.ri-sun-foggy-line:before {
  content: "";
}

.ri-sun-line:before {
  content: "";
}

.ri-superscript-2:before {
  content: "";
}

.ri-superscript:before {
  content: "";
}

.ri-surgical-mask-fill:before {
  content: "";
}

.ri-surgical-mask-line:before {
  content: "";
}

.ri-surround-sound-fill:before {
  content: "";
}

.ri-surround-sound-line:before {
  content: "";
}

.ri-survey-fill:before {
  content: "";
}

.ri-survey-line:before {
  content: "";
}

.ri-swap-box-fill:before {
  content: "";
}

.ri-swap-box-line:before {
  content: "";
}

.ri-swap-fill:before {
  content: "";
}

.ri-swap-line:before {
  content: "";
}

.ri-switch-fill:before {
  content: "";
}

.ri-switch-line:before {
  content: "";
}

.ri-sword-fill:before {
  content: "";
}

.ri-sword-line:before {
  content: "";
}

.ri-syringe-fill:before {
  content: "";
}

.ri-syringe-line:before {
  content: "";
}

.ri-t-box-fill:before {
  content: "";
}

.ri-t-box-line:before {
  content: "";
}

.ri-t-shirt-2-fill:before {
  content: "";
}

.ri-t-shirt-2-line:before {
  content: "";
}

.ri-t-shirt-air-fill:before {
  content: "";
}

.ri-t-shirt-air-line:before {
  content: "";
}

.ri-t-shirt-fill:before {
  content: "";
}

.ri-t-shirt-line:before {
  content: "";
}

.ri-table-2:before {
  content: "";
}

.ri-table-alt-fill:before {
  content: "";
}

.ri-table-alt-line:before {
  content: "";
}

.ri-table-fill:before {
  content: "";
}

.ri-table-line:before {
  content: "";
}

.ri-tablet-fill:before {
  content: "";
}

.ri-tablet-line:before {
  content: "";
}

.ri-takeaway-fill:before {
  content: "";
}

.ri-takeaway-line:before {
  content: "";
}

.ri-taobao-fill:before {
  content: "";
}

.ri-taobao-line:before {
  content: "";
}

.ri-tape-fill:before {
  content: "";
}

.ri-tape-line:before {
  content: "";
}

.ri-task-fill:before {
  content: "";
}

.ri-task-line:before {
  content: "";
}

.ri-taxi-fill:before {
  content: "";
}

.ri-taxi-line:before {
  content: "";
}

.ri-taxi-wifi-fill:before {
  content: "";
}

.ri-taxi-wifi-line:before {
  content: "";
}

.ri-team-fill:before {
  content: "";
}

.ri-team-line:before {
  content: "";
}

.ri-telegram-fill:before {
  content: "";
}

.ri-telegram-line:before {
  content: "";
}

.ri-temp-cold-fill:before {
  content: "";
}

.ri-temp-cold-line:before {
  content: "";
}

.ri-temp-hot-fill:before {
  content: "";
}

.ri-temp-hot-line:before {
  content: "";
}

.ri-terminal-box-fill:before {
  content: "";
}

.ri-terminal-box-line:before {
  content: "";
}

.ri-terminal-fill:before {
  content: "";
}

.ri-terminal-line:before {
  content: "";
}

.ri-terminal-window-fill:before {
  content: "";
}

.ri-terminal-window-line:before {
  content: "";
}

.ri-test-tube-fill:before {
  content: "";
}

.ri-test-tube-line:before {
  content: "";
}

.ri-text-direction-l:before {
  content: "";
}

.ri-text-direction-r:before {
  content: "";
}

.ri-text-spacing:before {
  content: "";
}

.ri-text-wrap:before {
  content: "";
}

.ri-text:before {
  content: "";
}

.ri-thermometer-fill:before {
  content: "";
}

.ri-thermometer-line:before {
  content: "";
}

.ri-thumb-down-fill:before {
  content: "";
}

.ri-thumb-down-line:before {
  content: "";
}

.ri-thumb-up-fill:before {
  content: "";
}

.ri-thumb-up-line:before {
  content: "";
}

.ri-thunderstorms-fill:before {
  content: "";
}

.ri-thunderstorms-line:before {
  content: "";
}

.ri-ticket-2-fill:before {
  content: "";
}

.ri-ticket-2-line:before {
  content: "";
}

.ri-ticket-fill:before {
  content: "";
}

.ri-ticket-line:before {
  content: "";
}

.ri-time-fill:before {
  content: "";
}

.ri-time-line:before {
  content: "";
}

.ri-timer-2-fill:before {
  content: "";
}

.ri-timer-2-line:before {
  content: "";
}

.ri-timer-fill:before {
  content: "";
}

.ri-timer-flash-fill:before {
  content: "";
}

.ri-timer-flash-line:before {
  content: "";
}

.ri-timer-line:before {
  content: "";
}

.ri-todo-fill:before {
  content: "";
}

.ri-todo-line:before {
  content: "";
}

.ri-toggle-fill:before {
  content: "";
}

.ri-toggle-line:before {
  content: "";
}

.ri-tools-fill:before {
  content: "";
}

.ri-tools-line:before {
  content: "";
}

.ri-tornado-fill:before {
  content: "";
}

.ri-tornado-line:before {
  content: "";
}

.ri-trademark-fill:before {
  content: "";
}

.ri-trademark-line:before {
  content: "";
}

.ri-traffic-light-fill:before {
  content: "";
}

.ri-traffic-light-line:before {
  content: "";
}

.ri-train-fill:before {
  content: "";
}

.ri-train-line:before {
  content: "";
}

.ri-train-wifi-fill:before {
  content: "";
}

.ri-train-wifi-line:before {
  content: "";
}

.ri-translate-2:before {
  content: "";
}

.ri-translate:before {
  content: "";
}

.ri-travesti-fill:before {
  content: "";
}

.ri-travesti-line:before {
  content: "";
}

.ri-treasure-map-fill:before {
  content: "";
}

.ri-treasure-map-line:before {
  content: "";
}

.ri-trello-fill:before {
  content: "";
}

.ri-trello-line:before {
  content: "";
}

.ri-trophy-fill:before {
  content: "";
}

.ri-trophy-line:before {
  content: "";
}

.ri-truck-fill:before {
  content: "";
}

.ri-truck-line:before {
  content: "";
}

.ri-tumblr-fill:before {
  content: "";
}

.ri-tumblr-line:before {
  content: "";
}

.ri-tv-2-fill:before {
  content: "";
}

.ri-tv-2-line:before {
  content: "";
}

.ri-tv-fill:before {
  content: "";
}

.ri-tv-line:before {
  content: "";
}

.ri-twitch-fill:before {
  content: "";
}

.ri-twitch-line:before {
  content: "";
}

.ri-twitter-fill:before {
  content: "";
}

.ri-twitter-line:before {
  content: "";
}

.ri-typhoon-fill:before {
  content: "";
}

.ri-typhoon-line:before {
  content: "";
}

.ri-u-disk-fill:before {
  content: "";
}

.ri-u-disk-line:before {
  content: "";
}

.ri-ubuntu-fill:before {
  content: "";
}

.ri-ubuntu-line:before {
  content: "";
}

.ri-umbrella-fill:before {
  content: "";
}

.ri-umbrella-line:before {
  content: "";
}

.ri-underline:before {
  content: "";
}

.ri-uninstall-fill:before {
  content: "";
}

.ri-uninstall-line:before {
  content: "";
}

.ri-unsplash-fill:before {
  content: "";
}

.ri-unsplash-line:before {
  content: "";
}

.ri-upload-2-fill:before {
  content: "";
}

.ri-upload-2-line:before {
  content: "";
}

.ri-upload-cloud-2-fill:before {
  content: "";
}

.ri-upload-cloud-2-line:before {
  content: "";
}

.ri-upload-cloud-fill:before {
  content: "";
}

.ri-upload-cloud-line:before {
  content: "";
}

.ri-upload-fill:before {
  content: "";
}

.ri-upload-line:before {
  content: "";
}

.ri-usb-fill:before {
  content: "";
}

.ri-usb-line:before {
  content: "";
}

.ri-user-2-fill:before {
  content: "";
}

.ri-user-2-line:before {
  content: "";
}

.ri-user-3-fill:before {
  content: "";
}

.ri-user-3-line:before {
  content: "";
}

.ri-user-4-fill:before {
  content: "";
}

.ri-user-4-line:before {
  content: "";
}

.ri-user-5-fill:before {
  content: "";
}

.ri-user-5-line:before {
  content: "";
}

.ri-user-6-fill:before {
  content: "";
}

.ri-user-6-line:before {
  content: "";
}

.ri-user-add-fill:before {
  content: "";
}

.ri-user-add-line:before {
  content: "";
}

.ri-user-fill:before {
  content: "";
}

.ri-user-follow-fill:before {
  content: "";
}

.ri-user-follow-line:before {
  content: "";
}

.ri-user-heart-fill:before {
  content: "";
}

.ri-user-heart-line:before {
  content: "";
}

.ri-user-line:before {
  content: "";
}

.ri-user-location-fill:before {
  content: "";
}

.ri-user-location-line:before {
  content: "";
}

.ri-user-received-2-fill:before {
  content: "";
}

.ri-user-received-2-line:before {
  content: "";
}

.ri-user-received-fill:before {
  content: "";
}

.ri-user-received-line:before {
  content: "";
}

.ri-user-search-fill:before {
  content: "";
}

.ri-user-search-line:before {
  content: "";
}

.ri-user-settings-fill:before {
  content: "";
}

.ri-user-settings-line:before {
  content: "";
}

.ri-user-shared-2-fill:before {
  content: "";
}

.ri-user-shared-2-line:before {
  content: "";
}

.ri-user-shared-fill:before {
  content: "";
}

.ri-user-shared-line:before {
  content: "";
}

.ri-user-smile-fill:before {
  content: "";
}

.ri-user-smile-line:before {
  content: "";
}

.ri-user-star-fill:before {
  content: "";
}

.ri-user-star-line:before {
  content: "";
}

.ri-user-unfollow-fill:before {
  content: "";
}

.ri-user-unfollow-line:before {
  content: "";
}

.ri-user-voice-fill:before {
  content: "";
}

.ri-user-voice-line:before {
  content: "";
}

.ri-video-add-fill:before {
  content: "";
}

.ri-video-add-line:before {
  content: "";
}

.ri-video-chat-fill:before {
  content: "";
}

.ri-video-chat-line:before {
  content: "";
}

.ri-video-download-fill:before {
  content: "";
}

.ri-video-download-line:before {
  content: "";
}

.ri-video-fill:before {
  content: "";
}

.ri-video-line:before {
  content: "";
}

.ri-video-upload-fill:before {
  content: "";
}

.ri-video-upload-line:before {
  content: "";
}

.ri-vidicon-2-fill:before {
  content: "";
}

.ri-vidicon-2-line:before {
  content: "";
}

.ri-vidicon-fill:before {
  content: "";
}

.ri-vidicon-line:before {
  content: "";
}

.ri-vimeo-fill:before {
  content: "";
}

.ri-vimeo-line:before {
  content: "";
}

.ri-vip-crown-2-fill:before {
  content: "";
}

.ri-vip-crown-2-line:before {
  content: "";
}

.ri-vip-crown-fill:before {
  content: "";
}

.ri-vip-crown-line:before {
  content: "";
}

.ri-vip-diamond-fill:before {
  content: "";
}

.ri-vip-diamond-line:before {
  content: "";
}

.ri-vip-fill:before {
  content: "";
}

.ri-vip-line:before {
  content: "";
}

.ri-virus-fill:before {
  content: "";
}

.ri-virus-line:before {
  content: "";
}

.ri-visa-fill:before {
  content: "";
}

.ri-visa-line:before {
  content: "";
}

.ri-voice-recognition-fill:before {
  content: "";
}

.ri-voice-recognition-line:before {
  content: "";
}

.ri-voiceprint-fill:before {
  content: "";
}

.ri-voiceprint-line:before {
  content: "";
}

.ri-volume-down-fill:before {
  content: "";
}

.ri-volume-down-line:before {
  content: "";
}

.ri-volume-mute-fill:before {
  content: "";
}

.ri-volume-mute-line:before {
  content: "";
}

.ri-volume-off-vibrate-fill:before {
  content: "";
}

.ri-volume-off-vibrate-line:before {
  content: "";
}

.ri-volume-up-fill:before {
  content: "";
}

.ri-volume-up-line:before {
  content: "";
}

.ri-volume-vibrate-fill:before {
  content: "";
}

.ri-volume-vibrate-line:before {
  content: "";
}

.ri-vuejs-fill:before {
  content: "";
}

.ri-vuejs-line:before {
  content: "";
}

.ri-walk-fill:before {
  content: "";
}

.ri-walk-line:before {
  content: "";
}

.ri-wallet-2-fill:before {
  content: "";
}

.ri-wallet-2-line:before {
  content: "";
}

.ri-wallet-3-fill:before {
  content: "";
}

.ri-wallet-3-line:before {
  content: "";
}

.ri-wallet-fill:before {
  content: "";
}

.ri-wallet-line:before {
  content: "";
}

.ri-water-flash-fill:before {
  content: "";
}

.ri-water-flash-line:before {
  content: "";
}

.ri-webcam-fill:before {
  content: "";
}

.ri-webcam-line:before {
  content: "";
}

.ri-wechat-2-fill:before {
  content: "";
}

.ri-wechat-2-line:before {
  content: "";
}

.ri-wechat-fill:before {
  content: "";
}

.ri-wechat-line:before {
  content: "";
}

.ri-wechat-pay-fill:before {
  content: "";
}

.ri-wechat-pay-line:before {
  content: "";
}

.ri-weibo-fill:before {
  content: "";
}

.ri-weibo-line:before {
  content: "";
}

.ri-whatsapp-fill:before {
  content: "";
}

.ri-whatsapp-line:before {
  content: "";
}

.ri-wheelchair-fill:before {
  content: "";
}

.ri-wheelchair-line:before {
  content: "";
}

.ri-wifi-fill:before {
  content: "";
}

.ri-wifi-line:before {
  content: "";
}

.ri-wifi-off-fill:before {
  content: "";
}

.ri-wifi-off-line:before {
  content: "";
}

.ri-window-2-fill:before {
  content: "";
}

.ri-window-2-line:before {
  content: "";
}

.ri-window-fill:before {
  content: "";
}

.ri-window-line:before {
  content: "";
}

.ri-windows-fill:before {
  content: "";
}

.ri-windows-line:before {
  content: "";
}

.ri-windy-fill:before {
  content: "";
}

.ri-windy-line:before {
  content: "";
}

.ri-wireless-charging-fill:before {
  content: "";
}

.ri-wireless-charging-line:before {
  content: "";
}

.ri-women-fill:before {
  content: "";
}

.ri-women-line:before {
  content: "";
}

.ri-wubi-input:before {
  content: "";
}

.ri-xbox-fill:before {
  content: "";
}

.ri-xbox-line:before {
  content: "";
}

.ri-xing-fill:before {
  content: "";
}

.ri-xing-line:before {
  content: "";
}

.ri-youtube-fill:before {
  content: "";
}

.ri-youtube-line:before {
  content: "";
}

.ri-zcool-fill:before {
  content: "";
}

.ri-zcool-line:before {
  content: "";
}

.ri-zhihu-fill:before {
  content: "";
}

.ri-zhihu-line:before {
  content: "";
}

.ri-zoom-in-fill:before {
  content: "";
}

.ri-zoom-in-line:before {
  content: "";
}

.ri-zoom-out-fill:before {
  content: "";
}

.ri-zoom-out-line:before {
  content: "";
}

.ri-zzz-fill:before {
  content: "";
}

.ri-zzz-line:before {
  content: "";
}

/*# sourceMappingURL=index.d2570618.css.map */
