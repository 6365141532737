@font-face {
  font-family: "StabilGrotesk";
  src: url("../assets/fonts/StabilGrotesk/StabilGrotesk-Light.otf");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "StabilGrotesk";
  src: url("../assets/fonts/StabilGrotesk/StabilGrotesk-Medium.otf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "KlarheitGrotesk";
  src: url("../assets/fonts/KlarheitGrotesk/KlarheitGrotesk-Medium.ttf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "KlarheitGrotesk";
  src: url("../assets/fonts/KlarheitGrotesk/KlarheitGrotesk-Semibold.ttf");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "KlarheitGrotesk";
  src: url("../assets/fonts/KlarheitGrotesk/KlarheitGrotesk-Bold.ttf");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "KlarheitGrotesk";
  src: url("../assets/fonts/KlarheitGrotesk/KlarheitGrotesk-Book.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

/* @font-face {
  font-family: "Phonk";
  src: url("../assets/fonts/Phonk/phonk-regular-demo.otf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
} */
