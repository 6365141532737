@import "./typography.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  color: #454545;
  font-family: "StabilGrotesk", georgia, serif;
  font-weight: normal;
  word-wrap: break-word;
  font-kerning: normal;
  position: relative;
}

.clickable-list-item::after {
  content: "";
  width: 100%;
  height: 1.5px;
  border: 1px solid #d1d1d1;
  display: block;
}

.clickable-list-item:last-child::after {
  border: none;
}

div.clickable-list-item:last-child {
  border-radius: 0px 0px 12px 12px;
}

.stepper-link::after {
  content: "";
  width: 24px;
  height: 1.2px;
  background-color: #d1d1d1;
  position: absolute;
  top: 16px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.grotesk {
  font-family: "KlarheitGrotesk", georgia, serif;
}

.phonk {
  /* font-family: "Phonk", georgia, serif; */
}

/* PAGINATION */
.lr-pagination {
  @apply flex space-x-1 text-xs font-semibold grotesk items-center justify-start;
}
.lr-pagination-item {
  @apply border border-[#ECECEC] rounded-[4px] h-6 px-2 text-[#454545] py-1 leading-4;
}

.lr-pagination-item_active {
  @apply bg-[#454545] text-white h-6 px-2 py-1 leading-4;
}
.lr-pagination-previous,
.lr-pagination-next {
  @apply bg-[#D1D1D1] text-white rounded-[4px] h-6 px-1 flex items-center justify-center pt-[5px];
}
